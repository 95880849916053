import { useCallback } from "react";
import { useLocation, useHistory } from "react-router-dom";
import URI from "urijs";
import { BeneficiaryAndApiHeaders } from "../@types";
import routes from "../app/routes";
import {
  beneKeysMatch,
  beneLoginAndUpdateHandler,
} from "./beneficiaryInfoHelpers";
import { useAppContext } from "./context-hooks/useAppContext";
import { parseSearchParams } from "./objectUtilities";
import { useSearchParams } from "./routing-hooks/useSearchParams";
import { useYearPart } from "./yearFlagHelpers";

/**
 * Hook provides all necessary values to `beneLoginAndUpdateHandler`, which is
 * returned memoized for all its dependencies
 */
export const useBeneLoginAndUpdateHandler = (
  beneInfoWithHeaders: BeneficiaryAndApiHeaders | undefined
) => {
  const {
    state: { beneficiary: stateBene, mbpHandoffFailed, pharmacies },
    dispatch,
  } = useAppContext();
  const location = useLocation();
  const history = useHistory();
  const { isOutsideOpenEnrollment } = useYearPart();
  const { relay } = parseSearchParams(URI.parseQuery(location.search));
  const isLoginCallbackRoute = location.pathname === routes.slsCallback;
  const isLandingPage = location.pathname === "/";
  const fetchedBene = beneInfoWithHeaders?.beneficiary;
  const hasFetchedBene = !!fetchedBene;
  const hasStateBene = !!stateBene;
  /**
   * When `beneLoginAndUpdateHandler` is called, if beneinfo was fetched but it
   * hasn't been added to app state, or if there's both cached and fetched bene
   * info, but their keys don't match, the store will need to be refreshed (this
   * value may be referenced after the store has been updated)
   */
  const needsUserRefresh =
    (hasFetchedBene && !hasStateBene) ||
    (hasFetchedBene && hasStateBene && !beneKeysMatch(stateBene, fetchedBene));
  const runOnce =
    beneInfoWithHeaders && (needsUserRefresh || isLoginCallbackRoute);

  const { selectedLanguage: routeLang } = useSearchParams(true);
  const beneHandler = useCallback(
    () =>
      beneLoginAndUpdateHandler({
        beneInfoWithHeaders,
        dispatch,
        hasStateBene,
        history,
        isLandingPage,
        isLoginCallbackRoute,
        isOutsideOpenEnrollment,
        mbpHandoffFailed,
        needsUserRefresh,
        pharmacies,
        relay,
        routeLang,
      }),
    [
      beneInfoWithHeaders,
      dispatch,
      hasStateBene,
      history,
      isLandingPage,
      isLoginCallbackRoute,
      isOutsideOpenEnrollment,
      mbpHandoffFailed,
      needsUserRefresh,
      pharmacies,
      relay,
      routeLang,
    ]
  );
  return {
    beneLoginAndUpdateHandler: beneInfoWithHeaders
      ? beneHandler
      : () => Promise.resolve(),
    runOnce,
  };
};
