import React, { FC } from "react";
import { DateField } from "@cmsgov/ds-medicare-gov";
import { MCTDate } from "../@types";
import { useTypedTranslate } from "../helpers/intlHooks";
import { isValidBirthdate, dateValidator } from "../helpers/dateHelpers";
import { limitDateInputs } from "../helpers/objectUtilities";

/**
 * function is needed for the date to be sent to the onChange function
 * trim() allows a user to add spaces but sends a trimmed version to `enrollData`
 */
const dateFormatter = (date: MCTDate): MCTDate => {
  return {
    month: date.month.substring(0, 2).trim(),
    day: date.day.substring(0, 2).trim(),
    year: date.year.substring(0, 4).trim(),
  };
};

export interface DOBValidatorProps {
  onComplete: (dob: MCTDate) => void;
  dob: MCTDate;
  errorMessage?: string;
  onComponentBlur?: () => void;
  dobTouched: boolean;
}

export const DOBValidator: FC<DOBValidatorProps> = ({
  onComplete,
  errorMessage = "",
  onComponentBlur,
  dobTouched,
  dob,
}) => {
  // * Translation
  const t = useTypedTranslate();

  // * Consts

  // verify individual date component first
  const { validDay, validMonth, validYear } = dateValidator(dob);
  // verify entire date
  const isValidDob = isValidBirthdate(dob);

  /** true if there is a specific error we can point the user to (like a 13th month) */
  const hasIndividualError = !validDay || !validMonth || !validYear;

  /** true if the date is invalid, but we can't point to a single issue (like a future date) */
  const triggerAllErrors =
    !hasIndividualError && (!isValidDob || !!errorMessage);

  const monthInvalid = dobTouched && (!validMonth || triggerAllErrors);
  const dayInvalid = dobTouched && (!validDay || triggerAllErrors);
  const yearInvalid = dobTouched && (!validYear || triggerAllErrors);

  // * Field Vars

  const makeFieldVars = (period: keyof MCTDate): Record<string, unknown> => {
    return {
      [`${period}DefaultValue`]: dob && dob[period],
      [`${period}Label`]: t(`dob.${period}_label`),
    };
  };

  const dayVars = makeFieldVars("day");
  const monthVars = makeFieldVars("month");
  const yearVars = makeFieldVars("year");

  // * Functions

  const onChange = (_: Event | null, dob: MCTDate): void => {
    limitDateInputs();
    onComplete(dob);
  };

  return (
    <DateField
      dateFormatter={dateFormatter}
      onChange={onChange}
      onComponentBlur={onComponentBlur}
      label={t("csr_landing.dob")}
      errorMessage={
        errorMessage
          ? errorMessage
          : monthInvalid || dayInvalid || yearInvalid
          ? t("enroll_form.enter_dob")
          : undefined
      }
      hint={t("dob.hint")}
      className="ds-u-margin-bottom--2"
      monthInvalid={monthInvalid}
      dayInvalid={dayInvalid}
      yearInvalid={yearInvalid}
      {...dayVars}
      {...monthVars}
      {...yearVars}
    />
  );
};
