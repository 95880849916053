import React from "react";
import { useFlags } from "launchdarkly-react-client-sdk";
import { useCallback, useEffect } from "react";
import { Envs, FCWithChildren, UserLanguage } from "../@types";
import {
  getEnvironment,
  getSubdomainForEnvironment,
} from "../helpers/urlHelpers";
import { getGlobalHeaderAssetEntries } from "../helpers/maxGlobalHeaderHelpers";
import { getLoginUrl, hasLocalSession } from "../helpers/loginHelpers";
import { shouldDisplayGlobalHeader } from "../helpers/routeHelpers";
import { useIsCsrSession } from "../helpers/csrHelpers";
import { hideGlobalHeaderPaths } from "../app/routes";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { getBeneFirstAndLastName } from "../helpers/beneficiaryInfoHelpers";
import { useTranslate } from "../helpers/intlHooks";
import { config } from "../config";
import { useAppContext } from "../helpers/context-hooks/useAppContext";
import { setSubdomain } from "../helpers/urlHelpers";

export const CONSISTENT_HEADER_TEST_ID = "ch-root";

const MaxGlobalHeader: FCWithChildren<RouteComponentProps> = ({
  children,
  location,
}) => {
  // Determine whether the global (consistent) header should be displayed,
  // based on the current route - Also see `/components/Header.tsx`
  const displayGlobalHeader = shouldDisplayGlobalHeader(
    location,
    hideGlobalHeaderPaths
  );
  const { feEnableChat, feEnableAuthentication } = useFlags();
  const { state } = useAppContext();
  const isCsr = useIsCsrSession();
  const t = useTranslate();
  const GLOBAL_HEADER_ID = "root";

  // Takes the provided array, parses it and loads all of the JS and CSS files based on their file extension
  const importFilesFromArray = useCallback(
    (files: string[], rootURL: string): void => {
      const cssFiles = files.filter(file => file.endsWith(".css"));
      const jsFiles = files.filter(file => file.endsWith(".js"));

      // The order matters here: CSS files should be loaded first
      cssFiles.forEach(file => addCssFileToHead(rootURL + file));
      jsFiles.forEach(file => addJsFileToHead(rootURL + file));
    },
    []
  );

  // Gets and parses the entrypoints node of the asset manifest file
  const loadFilesFromManifest = useCallback(
    (rootURL: string, manifest: string): void => {
      const manifestURL = rootURL + manifest;

      getGlobalHeaderAssetEntries(manifestURL).then(entrypoints =>
        importFilesFromArray(entrypoints, rootURL)
      );
    },
    [importFilesFromArray]
  );

  // Adds a JS file to the document head
  function addJsFileToHead(url: string): void {
    const jsElement = document.createElement("script");
    jsElement.src = url;
    jsElement.type = "text/javascript";
    jsElement.setAttribute("data-ch-asset", "true");

    const head = document.head;
    head.insertBefore(jsElement, head.childNodes[0]);
  }

  // Adds a CSS file to the document head
  function addCssFileToHead(url: string): void {
    const cssElement = document.createElement("link");
    cssElement.href = url;
    cssElement.rel = "stylesheet";
    cssElement.type = "text/css";
    cssElement.setAttribute("data-ch-asset", "true");

    const head = document.head;
    head.insertBefore(cssElement, head.childNodes[0]);
  }

  const setHeaderProperties = useCallback(
    (globalHeader: HTMLDivElement): void => {
      const oppositeLanguage =
        state.language === UserLanguage.ENGLISH ? "es" : "en";
      let langToggleLink = window.location.href.replace(
        /lang=(en|es)/gim,
        `lang=${oppositeLanguage}`
      );
      const env = getEnvironment();
      if (env !== Envs.local) {
        langToggleLink = setSubdomain({
          subdomain: getSubdomainForEnvironment(),
          url: langToggleLink,
        });
      }
      const lang = state.language;
      const loginUrl = getLoginUrl(state.language);
      const logoutUrl = `${window.location.origin}${window.location.pathname}#/logout`;
      const hasSession = hasLocalSession();
      const hasBene = !!state.beneficiary;
      const loggedIn = hasSession && hasBene;
      const headerType = isCsr && loggedIn ? "CSR" : "MAX";
      const currentViewPage = `MCT-${location.pathname.replace("/", "")}`;

      globalHeader.setAttribute("lang", lang);
      globalHeader.setAttribute("lang-toggle-link", langToggleLink);
      globalHeader.setAttribute("login-url", loginUrl);
      globalHeader.setAttribute("logout-url", logoutUrl);
      globalHeader.setAttribute(
        "enable-live-chat",
        Boolean(feEnableChat).toString()
      );
      globalHeader.setAttribute("live-chat-viewpage", currentViewPage);
      globalHeader.setAttribute("header-type", headerType);
      globalHeader.setAttribute(
        "disable-login",
        Boolean(!feEnableAuthentication).toString()
      );
      globalHeader.setAttribute("data-testid", CONSISTENT_HEADER_TEST_ID);

      if (state.beneficiary) {
        globalHeader.setAttribute(
          "beneficiary",
          getBeneFirstAndLastName(state.beneficiary, t)
        );
      }
    },
    [
      feEnableAuthentication,
      feEnableChat,
      location.pathname,
      state.beneficiary,
      isCsr,
      state.language,
      t,
    ]
  );

  const createGlobalHeader = useCallback((): void => {
    const globalHeader = document.createElement("div");
    globalHeader.id = GLOBAL_HEADER_ID;

    setHeaderProperties(globalHeader);

    const appRoot = document.getElementById("mpf-root");

    // Add the header as the first child of the app div
    appRoot?.insertBefore(globalHeader, appRoot.childNodes[0]);

    const env = getEnvironment();

    const manifestUrls = {
      [Envs.local]: "https://frontend.test.medicare.gov/",
      [Envs.test]: "https://frontend.test.medicare.gov/",
      [Envs.dev]: "https://frontend.dev.medicare.gov/",
      [Envs.imp]: "https://frontend.imp.medicare.gov/",
      [Envs.prod]: "https://frontend.medicare.gov/",
    };

    const manifestUrl = manifestUrls[env];

    loadFilesFromManifest(manifestUrl, "asset-manifest.json");
  }, [loadFilesFromManifest, setHeaderProperties]);

  // When any of the global header properties change, we must set the new values on the element
  useEffect(() => {
    let chMenuObserver: MutationObserver | void | undefined = undefined;
    (async () => {
      if (!displayGlobalHeader) {
        return;
      }
      const globalHeader = document.getElementById(GLOBAL_HEADER_ID);
      // Header is on, but no header exist in the DOM, create it
      if (!globalHeader) {
        createGlobalHeader();
      } else {
        // Header is on and header already exists so update the properties
        setHeaderProperties(globalHeader as HTMLDivElement);
      }
      if (state.beneficiary && config.DEBUG) {
        const { initializeLocalLogouts } = await import("./helpers");
        chMenuObserver = initializeLocalLogouts();
      }
    })();
    return () => {
      chMenuObserver?.disconnect();
    };
  }, [
    createGlobalHeader,
    displayGlobalHeader,
    feEnableAuthentication,
    feEnableChat,
    location,
    setHeaderProperties,
    state.beneficiary,
    state.language,
  ]);

  return <>{children}</>;
};

export default withRouter(MaxGlobalHeader);
