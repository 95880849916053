import React, { useState, useEffect, useRef, Suspense } from "react";
import { Route, Switch, useLocation } from "react-router-dom";
import {
  ReferrerDetector,
  ScrollRestoration,
  StateValidator,
  YearSwitchBanner,
  Footer,
  GlobalSessionHandler,
  PrivateRoute,
  LoadingMask,
} from "../components";
import { InjectIntlContext } from "../helpers/intlHooks";
import { cookiesAddedOrRemoved } from "../helpers/cookieHelpers";
import { getLdUser } from "../helpers/launchDarklyHelpers";
import { useYearSwitchBanner } from "../helpers/yearHelpers";
import * as Pages from "../pages";
import messages_en from "../translations/en-US.json";
import messages_es from "../translations/es-MX.json";
import Routes from "./routes";
import { useLDClient } from "launchdarkly-react-client-sdk";
import RouteToastHandler from "../components/RouteToastHandler";
import { IdleTimeout } from "../components";
import MaxGlobalHeader from "./MaxGlobalHeader";
import { useYearPart } from "../helpers/yearFlagHelpers";
import AnalyticsListeners from "../components/AnalyticsListeners";
import { IntlProvider } from "react-intl";
import { shouldPolyfill as shouldPolyfillRelativeTimeFormat } from "@formatjs/intl-relativetimeformat/should-polyfill";
import { shouldPolyfill as shouldPolyfillPluralRules } from "@formatjs/intl-pluralrules/should-polyfill";
import { AnonRoute } from "../components/AnonRoute";
import { getLanguageFromLocationOrState } from "../helpers/languageHelpers";
import { useAppContext } from "../helpers/context-hooks/useAppContext";
import { useBackendVersions } from "../helpers/query-hooks/useBackendVersions";
import { useMctFlags } from "../helpers/flag-hooks/useMctFlags";

const AppContent: React.FunctionComponent = () => {
  // * Context
  const { state } = useAppContext();

  // * Queries
  // this prefetches the backend version numbers.
  const { hasAttempted } = useBackendVersions();

  // * Hooks
  const location = useLocation();
  const showYearSwitchBanner = useYearSwitchBanner();

  // * Language
  const language = getLanguageFromLocationOrState({
    location,
    stateLang: state.language,
  });

  // The IntlProvider adds language localization to its children with a default language and translations map as props
  const messages = {
    en: messages_en,
    es: messages_es,
  };

  useEffect(() => {
    (async () => {
      await polyfill("en");
      await polyfill("es");
    })();
  }, []);

  // * Flags
  const flags = useMctFlags();
  const { feMaintenanceMode } = flags;
  const { isOutsideOpenEnrollment } = useYearPart();

  /**
   * Get the LaunchDarkly client.
   * Provide a callback for updating the user flags if a polled cookie changes.
   */
  const ldClient = useLDClient();

  const updateLdUser = () => {
    const ldUser = getLdUser(state);
    ldClient?.identify(ldUser, undefined, err => {
      if (err) {
        console.error(err);
      }
    });
  };

  // * State
  const [ldFlagTimeout, setLdFlagTimeout] = useState<
    NodeJS.Timeout | undefined
  >();
  const [couldNotRetrieveLdFlags, setCouldNotRetrieveLdFlags] = useState(false);
  const [ldFlagsAvailable, setLdFlagsAvailable] = useState(false);

  // * Constants
  const isLoadingFlags = !(ldFlagsAvailable || couldNotRetrieveLdFlags);
  const isLoadingForTheFirstTime = isLoadingFlags || !hasAttempted;

  // * Effects

  useEffect(() => {
    const ldFlagsAreAvailable = Object.getOwnPropertyNames(flags).length > 0;

    setLdFlagsAvailable(ldFlagsAreAvailable);

    if (!ldFlagsAreAvailable) {
      setLdFlagTimeout(
        setTimeout(() => {
          setCouldNotRetrieveLdFlags(true);
        }, 5000)
      );
    } else {
      if (ldFlagTimeout) {
        clearTimeout(ldFlagTimeout);
        setLdFlagTimeout(undefined);
      }
      setCouldNotRetrieveLdFlags(false);
    }
  }, [flags]);

  /**
   * Watch for an update to cookies and confirm flags have been
   * retrieved.
   * If the specified cookie (key) has been added or removed, update flags,
   * accordingly.
   */
  const useCookieChanged = (key: string) => {
    const ref = useRef(document.cookie);
    useEffect(() => {
      if (couldNotRetrieveLdFlags) {
        return;
      }
      const cookiesChanged = cookiesAddedOrRemoved(key, ref.current)();
      if (cookiesChanged) {
        updateLdUser();
      }
      ref.current = document.cookie;
    }, [document.cookie, couldNotRetrieveLdFlags]);
    return ref.current;
  };

  useCookieChanged("sid");

  /**
   * Watch for an update to `state.beneficiary` and confirm flags have been
   * retrieved.
   * If beneficiary data is available, update flags, accordingly.
   */
  useEffect(() => {
    if (couldNotRetrieveLdFlags || !state.beneficiary) {
      return;
    }
    updateLdUser();
  }, [couldNotRetrieveLdFlags, state.beneficiary]);

  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <InjectIntlContext>
        <MaxGlobalHeader>
          <StateValidator>
            <ScrollRestoration>
              <ReferrerDetector>
                <GlobalSessionHandler>
                  <IdleTimeout>
                    <AnalyticsListeners>
                      <RouteToastHandler>
                        <div className="ds-base mct-l-app">
                          {showYearSwitchBanner && <YearSwitchBanner />}
                          <Suspense
                            fallback={
                              <LoadingMask
                                loading={true}
                                message={
                                  messages[state.language]["app.loading"]
                                }
                              />
                            }
                          >
                            {isLoadingForTheFirstTime ? (
                              <Pages.LoadingPage />
                            ) : (
                              <div className="AppContent mct-l-app__content">
                                <main className="app__main mct-l-app__main e2e-app-main">
                                  {feMaintenanceMode ||
                                  couldNotRetrieveLdFlags ? (
                                    <Pages.MaintenancePage />
                                  ) : (
                                    <Switch>
                                      <Route
                                        path={Routes.searchResults}
                                        component={Pages.SearchResultsPage}
                                      />
                                      <Route
                                        path={Routes.sanctionedPlans}
                                        component={Pages.SanctionedPlansPage}
                                      />
                                      <Route
                                        path={Routes.drugSearchPreferences}
                                        component={
                                          Pages.DrugSearchPreferencesPage
                                        }
                                      />
                                      {!isOutsideOpenEnrollment && (
                                        <Route
                                          path={Routes.yearOverYear}
                                          component={Pages.PlanComparePage}
                                        />
                                      )}
                                      <Route
                                        path={Routes.comparePlans}
                                        component={Pages.PlanComparePage}
                                      />
                                      <Route
                                        path={Routes.planDetails}
                                        component={Pages.PlanDetailsPage}
                                      />
                                      <Route
                                        path={Routes.managePrescriptions}
                                        component={
                                          Pages.ManagePrescriptionsPage
                                        }
                                      />
                                      <Route
                                        path={Routes.prescriptionsList}
                                        component={
                                          Pages.ManagePrescriptionsPage
                                        }
                                      />
                                      <Route
                                        path={Routes.managePharmacies}
                                        component={Pages.ManagePharmaciesPage}
                                      />
                                      <Route
                                        path={Routes.inNetworkPharmacy}
                                        component={
                                          Pages.InNetworkPharmaciesPage
                                        }
                                      />
                                      <Route
                                        path={Routes.pharmacy}
                                        component={Pages.SelectPharmaciesPage}
                                      />
                                      <Route
                                        path={Routes.questionRouting}
                                        component={Pages.QuestionRoutingPage}
                                      />
                                      <Route
                                        path={Routes.lisQuestions}
                                        component={Pages.LISQuestionsPage}
                                      />
                                      <Route
                                        path={Routes.enroll}
                                        component={Pages.EnrollmentFormPage}
                                      />
                                      <Route
                                        path={Routes.csrLanding}
                                        component={Pages.CSRLandingPage}
                                      />
                                      <Route
                                        path={Routes.pdePage}
                                        component={Pages.PDEPage}
                                      />
                                      <Route
                                        path={Routes.version}
                                        component={Pages.VersionPage}
                                      />
                                      <Route
                                        path={Routes.planPreview}
                                        component={Pages.PlanPreviewPage}
                                      />
                                      <Route
                                        path={Routes.coverageWizard.options}
                                        component={
                                          Pages.CoverageWizardOptionsPage
                                        }
                                      />
                                      <Route
                                        path={Routes.coverageWizard.info}
                                        component={Pages.CoverageWizardInfoPage}
                                      />
                                      <Route
                                        path={Routes.coverageWizard.landingPage}
                                        component={
                                          Pages.CoverageWizardLandingPage
                                        }
                                      />
                                      <Route
                                        path={Routes.pace.plans}
                                        component={Pages.PacePlansPage}
                                      />
                                      <Route
                                        path={Routes.pace.landingPage}
                                        component={Pages.PaceLandingPage}
                                      />
                                      <Route
                                        path={Routes.pap.plans}
                                        component={Pages.PapPlansPage}
                                      />
                                      <Route
                                        path={Routes.spap.plans}
                                        component={Pages.SpapPlansPage}
                                      />
                                      <Route
                                        path={Routes.spap.landingPage}
                                        component={Pages.SpapLandingPage}
                                      />
                                      <PrivateRoute
                                        path={Routes.summary.lisQuestions}
                                      >
                                        <Pages.LISQuestionsPage
                                          isFromSummaryPage={true}
                                        />
                                      </PrivateRoute>
                                      <PrivateRoute
                                        path={Routes.summary.managePharmacies}
                                      >
                                        <Pages.ManagePharmaciesPage
                                          isFromSummaryPage={true}
                                        />
                                      </PrivateRoute>
                                      <PrivateRoute
                                        path={
                                          Routes.summary.managePrescriptions
                                        }
                                      >
                                        <Pages.ManagePrescriptionsPage
                                          isFromSummaryPage={true}
                                        />
                                      </PrivateRoute>

                                      <PrivateRoute
                                        path={Routes.summary.selectPharmacies}
                                      >
                                        <Pages.SelectPharmaciesPage
                                          isFromSummaryPage={true}
                                        />
                                      </PrivateRoute>
                                      <PrivateRoute
                                        path={
                                          Routes.summary.drugSearchPreferences
                                        }
                                      >
                                        <Pages.DrugSearchPreferencesPage
                                          isFromSummaryPage={true}
                                        />
                                      </PrivateRoute>
                                      <PrivateRoute
                                        path={Routes.summary.pdePage}
                                      >
                                        <Pages.PDEPage
                                          isFromSummaryPage={true}
                                        />
                                      </PrivateRoute>
                                      <PrivateRoute
                                        path={Routes.summary.searchResults}
                                      >
                                        <Pages.SearchResultsPage />
                                      </PrivateRoute>
                                      <PrivateRoute
                                        path={Routes.summary.landingPage}
                                      >
                                        <Pages.SummaryPage />
                                      </PrivateRoute>
                                      <Route
                                        path={Routes.pap.landingPage}
                                        component={Pages.PapLandingPage}
                                      />
                                      <Route
                                        path={Routes.logout}
                                        component={Pages.LogoutPage}
                                      />
                                      <Route
                                        path={Routes.medigap.plans}
                                        component={Pages.MedigapPlans}
                                      />
                                      <Route
                                        path={Routes.medigap.planDetails}
                                        component={Pages.MedigapPlanDetailsPage}
                                      />
                                      <Route
                                        path={Routes.medigap.policies}
                                        component={Pages.MedigapPoliciesPage}
                                      />
                                      <Route
                                        path={Routes.medigap.landingPage}
                                        component={Pages.MedigapLandingPage}
                                      />

                                      <AnonRoute
                                        path={Routes.newToMedicare}
                                        component={Pages.NewToMedicarePage}
                                      />

                                      <Route
                                        path={Routes.findPlansNow}
                                        component={Pages.FindPlansNowPage}
                                      />

                                      <Route
                                        path={Routes.csrGuestAccess}
                                        component={Pages.CSRGuestAccessPage}
                                      />

                                      <Route component={Pages.LandingPage} />
                                    </Switch>
                                  )}
                                </main>
                                {!location.hash.includes(
                                  Routes.planPreview.replace("/:id", "")
                                ) && <Footer />}
                              </div>
                            )}
                          </Suspense>
                        </div>
                      </RouteToastHandler>
                    </AnalyticsListeners>
                  </IdleTimeout>
                </GlobalSessionHandler>
              </ReferrerDetector>
            </ScrollRestoration>
          </StateValidator>
        </MaxGlobalHeader>
      </InjectIntlContext>
    </IntlProvider>
  );
};

async function polyfill(locale: string) {
  const unsupportedRelativeTimeLocale =
    shouldPolyfillRelativeTimeFormat(locale);
  const unsupportedPluralRulesLocale = shouldPolyfillPluralRules(locale);

  if (unsupportedRelativeTimeLocale) {
    // Load the polyfill 1st BEFORE loading data
    await import("@formatjs/intl-relativetimeformat/polyfill-force");
    await import(
      `@formatjs/intl-relativetimeformat/locale-data/${unsupportedRelativeTimeLocale}`
    );
  }

  if (unsupportedPluralRulesLocale) {
    // Load the polyfill 1st BEFORE loading data
    await import("@formatjs/intl-pluralrules/polyfill-force");
    await import(
      `@formatjs/intl-pluralrules/locale-data/${unsupportedRelativeTimeLocale}`
    );
  }
}

export default AppContent;
