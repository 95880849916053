import React from "react";
import HelpDrawerWrapper from "../HelpDrawerWrapper";
import classNames from "classnames";
import { getLoginUrl } from "../../helpers/loginHelpers";
import { useAppContext } from "../../helpers/context-hooks/useAppContext";
import { InfoCircleOutlineIcon } from "@cmsgov/ds-medicare-gov";
import { useTypedTranslate } from "../../helpers/intlHooks";
import { FormattedMessage } from "react-intl";

interface LISProgramsHelpDrawerProps {
  toggleText: string;
  toggleClassName?: string;
  onClickLogin: () => void;
}

const LISProgramItem = ({ phraseKey }: { phraseKey: string }) => {
  return (
    <li className="ds-u-padding-left--1" key={phraseKey}>
      <FormattedMessage
        id={phraseKey}
        values={{
          dt: chunks => <strong>{chunks}</strong>,
          dd: chunks => chunks,
        }}
      />
    </li>
  );
};

export const LISProgramsHelpDrawer = ({
  toggleText,
  toggleClassName,
  onClickLogin,
}: LISProgramsHelpDrawerProps) => {
  const t = useTypedTranslate();
  const { state } = useAppContext();

  return (
    <HelpDrawerWrapper
      toggleClassName={classNames(toggleClassName)}
      toggleText={toggleText}
      drawerTitle={t("extra_help_page.update.programs_help_drawer.title")}
      enableDefaultAnalytics={true}
      inlineToggle={true}
      toggleIcon={
        // Change z-index so hovering would show filled icon.
        <span style={{ position: "relative", zIndex: 1, left: "4px" }}>
          <InfoCircleOutlineIcon />
        </span>
      }
    >
      <p className="ds-u-margin-top--0">
        {t("extra_help_page.update.programs_help_drawer.overview")}
      </p>
      {/* need to use <ul><li></li></ul> because axe tool errors on the <div> when using <dl><div><dt></dt><dd></dd></div></dl> */}
      <ul className="ds-u-padding-left--3">
        <LISProgramItem phraseKey="extra_help_page.update.programs_help_drawer.medicaid" />
        <LISProgramItem phraseKey="extra_help_page.update.programs_help_drawer.ssi" />
        <LISProgramItem phraseKey="extra_help_page.update.programs_help_drawer.msp" />
        <LISProgramItem phraseKey="extra_help_page.update.programs_help_drawer.extra_help" />
      </ul>
      <p>
        <FormattedMessage
          id="extra_help_page.update.programs_help_drawer.not_sure"
          values={{
            b: chunks => <strong>{chunks}</strong>,
            link: chunks => (
              <a onClick={onClickLogin} href={getLoginUrl(state.language)}>
                {chunks}
              </a>
            ),
          }}
        />
      </p>
    </HelpDrawerWrapper>
  );
};
