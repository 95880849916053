import { Envs } from "../@types";
import { getEnvironment } from "./urlHelpers";

export const devDebug = (message: string): void => {
  const env = getEnvironment();
  const re = new RegExp(`${Envs.imp}|${Envs.test}|${Envs.local}`, "i");
  if (re.test(env)) {
    console?.debug(message);
  }
};

export const logMinutesFromMs = (ms: string | number): string =>
  `${parseFloat((parseInt(String(ms), 10) / (60 * 1000)).toFixed(2))} mins`;

export type ConsoleSuppressionLevels = Extract<
  keyof Console,
  "warn" | "error" | "log" | "debug"
>;
export const suppressConsoleOutput = ({
  level,
  logStrings,
}: {
  level: ConsoleSuppressionLevels;
  logStrings: string[];
}) => {
  const nativeConsoleLevel = global.console[level];
  global.console[level] = (...args) => {
    const argsString = args.join("");
    const suppressIt = logStrings.some(logString =>
      argsString.includes(logString)
    );
    if (suppressIt) {
      return;
    }
    return nativeConsoleLevel(...args);
  };
};
