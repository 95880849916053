import { ChoiceListProps } from "@cmsgov/design-system/dist/types/ChoiceList/ChoiceList";
import { ChoiceList } from "@cmsgov/ds-medicare-gov";
import React, {
  FC,
  FocusEventHandler,
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import { useTranslate } from "../helpers/intlHooks";
import { TranslationKey } from "../helpers/intlHooks";
import {
  removeAttributeValue,
  replaceAttributeValue,
} from "../helpers/domHelpers";

export type RequiredChoiceListProps = {
  /**
   * Used to indicate content revealed when (a) choice(s) has/have been selected
   * (e.g., another input or a New to Medicare answer)
   */
  "aria-describedby"?: string;
  /**
   * A string of space-delimited possible values to which `aria-describedby`
   * could be set. Used to update the value by removing all possible values and
   * adding a new one.
   */
  possibleAriaDescribedBy?: string;
  requiredErrorMessage?: ReactNode;
};

export const defaultErrorMessageKey: TranslationKey = "select_an_option";

export const RequiredChoiceList: FC<
  Omit<ChoiceListProps, "errorMessage" | "aria-describedby"> &
    RequiredChoiceListProps
> = ({
  choices,
  onChange,
  onComponentBlur,
  requiredErrorMessage,
  "aria-describedby": ariaDescribedBy,
  possibleAriaDescribedBy = "",
  ...props
}) => {
  // Hooks
  const t = useTranslate();

  // State
  const [errorMessage, setErrorMessage] = useState<ReactNode | null>(null);

  // * Refs
  const choiceElsRef = useRef<HTMLInputElement[]>([]);

  const validateChoiceList: FocusEventHandler<HTMLInputElement> = () => {
    const message = requiredErrorMessage || t(defaultErrorMessageKey);
    if (!choiceElsRef.current) {
      return;
    }
    const hasCheckedChoice = choiceElsRef.current.some(
      choice => choice.checked
    );
    setErrorMessage(hasCheckedChoice ? null : message);
  };

  const setAriaDescribedBy = useCallback(() => {
    if (!choiceElsRef.current || !ariaDescribedBy) {
      return;
    }
    const choiceEls = choiceElsRef.current;
    const fieldsetEl = choiceEls[0].closest("fieldset");
    const selectionMade = Array.from(choiceEls).some(choice => choice.checked);
    choiceEls.forEach(choice =>
      choice.checked
        ? replaceAttributeValue({
            el: choice,
            name: "aria-describedby",
            value: ariaDescribedBy,
            removeValue: possibleAriaDescribedBy,
          })
        : removeAttributeValue({
            el: choice,
            name: "aria-describedby",
            value: possibleAriaDescribedBy,
          })
    );
    if (!fieldsetEl) {
      return;
    }
    selectionMade
      ? replaceAttributeValue({
          el: fieldsetEl,
          name: "aria-describedby",
          value: ariaDescribedBy,
          removeValue: possibleAriaDescribedBy,
        })
      : removeAttributeValue({
          el: fieldsetEl,
          name: "aria-describedby",
          value: possibleAriaDescribedBy,
        });
  }, [ariaDescribedBy]);

  // Effects
  // On page load, set `aria-describedby` on any checked input
  useEffect(() => {
    setAriaDescribedBy();
  }, [setAriaDescribedBy]);

  return (
    <ChoiceList
      aria-invalid={!choices.some(choice => choice.checked)}
      choices={choices.map((choice, i) => {
        return {
          ...choice,
          inputRef: (node: HTMLInputElement | null) => {
            if (node) {
              // Make all inputs required
              node.required = true;
              choiceElsRef.current[i] = node;
            }
          },
        };
      })}
      errorMessage={errorMessage}
      onComponentBlur={e => {
        validateChoiceList(e);
        onComponentBlur && onComponentBlur(e);
      }}
      onChange={e => {
        setAriaDescribedBy();
        setErrorMessage(null);
        onChange && onChange(e);
      }}
      {...props}
    />
  );
};
