import {
  Action,
  AppState,
  PharmacyType,
  PrescriptionDrug,
  UserLanguage,
  PlanSNPType,
  EventTrackingPayload,
} from "../../@types";

import { saveBeneDrugs, saveBenePharmacies } from "../../api";
import { hasGlobalSession } from "../../helpers/loginHelpers";
// ! Do not attempt to import from re-exports (../../helpers) at the moment
// ! This breaks tests
import { cachedStore, storageKey } from "../../helpers/storeHelpers";
import { emptyEnrollData } from "../../helpers/enrollmentFormHelpers";
import { deduplicatePrescriptions } from "../../helpers/beneficiaryInfoHelpers";
import { ApiError, logError } from "../../helpers/errors";

import * as TypeGuards from "../../@types/guards";
import { isDSNP } from "../../helpers/lisHelpers";

const navigatorLanguage = navigator.language.split(/[-_]/)[0];
const defaultLanguage = (() => {
  switch (navigatorLanguage) {
    case UserLanguage.ENGLISH:
    case UserLanguage.SPANISH:
      return navigatorLanguage;
    default:
      return UserLanguage.ENGLISH;
  }
})();

export const getDefaultState = (): AppState => {
  const defaultState: AppState = {
    beneficiary: undefined,
    compareList: [],
    county: undefined,
    csr: undefined,
    csrGuestAccess: false,
    currentCoverage: undefined,
    cwCoverage: undefined,
    degradedIntegrations: [],
    demographicInfo: undefined,
    dismissedAlerts: [],
    enrollData: emptyEnrollData,
    fips: undefined,
    futureLis: undefined,
    language: defaultLanguage,
    lis: undefined,
    mapboxPharmacySuggestions: [],
    mbpHandoffFailed: false,
    mbpReferrerUri: "",
    multipleRolloverPlans: false,
    anonNewToMedicareAlertInfo: undefined,
    nextYearCoverage: undefined,
    pharmacies: [],
    pharmacySearchAddress: undefined,
    pharmacyType: PharmacyType.NONE,
    prescriptions: [],
    routeParams: {},
    searchResultsFilters: {
      drugCoverageFilter: undefined,
      insuranceCarrierFilter: "",
      insuranceCarriers: [],
      planCategoriesFilter: [],
      planCoveragesFilter: [],
      sortBy: undefined,
      starRatingFilter: "",
    },
    snpFilters: [],
    tealiumLoaded: false,
    trackedEvents: {},
    zipcode: undefined,
    rolloverPlanStatus: undefined,
  };
  return { ...defaultState };
};

export const initialAppState: AppState = cachedStore || getDefaultState();

/**
 * Restores `AppState` to "default" while preserving  `language` from the previous
 * session
 *
 * Deletes `cms-mct-store` (the store) from `sessionStorage`, but because the two
 * are always kept in sync, it will immediately be recreated with what is
 * returned here.
 */
export const resetState = ({
  language,
}: {
  language: UserLanguage;
}): AppState => {
  // Clear session storage relative to domain
  window.sessionStorage.removeItem(storageKey);
  const defaultState = getDefaultState();

  return {
    ...defaultState,
    language,
  };
};

export const updatePrescriptions = async ({
  prescriptions,
  state,
}: {
  prescriptions: PrescriptionDrug[];
  state: AppState;
}): Promise<void> => {
  if (hasGlobalSession(state)) {
    (async () => {
      try {
        await saveBeneDrugs(prescriptions);
      } catch (e) {
        logError(
          "Failed to save beneficiary drugs (AppStore/updatePrescriptions)",
          e as ApiError
        );
      }
    })();
  }
};

export const setPrescriptions = (
  state: AppState,
  { payload }: Action
): AppState => {
  let prescriptions = TypeGuards.hasPrescriptions(payload)
    ? payload
    : state.prescriptions;

  prescriptions = deduplicatePrescriptions(prescriptions);
  prescriptions.sort((a, b) => a.name.localeCompare(b.name));

  updatePrescriptions({ prescriptions, state });

  return { ...state, prescriptions };
};

export const addPrescription = (
  state: AppState,
  { payload: prescription }: Action
): AppState => {
  const prescriptions = TypeGuards.isPrescription(prescription)
    ? [...state.prescriptions, prescription]
    : state.prescriptions;

  prescriptions.sort((a, b) => a.name.localeCompare(b.name));

  updatePrescriptions({ prescriptions, state });

  return {
    ...state,
    prescriptions,
  };
};

export const editPrescription = (
  state: AppState,
  { payload }: Action
): AppState => {
  if (TypeGuards.hasIndex(payload) && TypeGuards.hasDrug(payload)) {
    const { index, drug } = payload;

    const prescriptions = state.prescriptions.map((prescription, i) => {
      if (i !== index) {
        return prescription;
      }

      return {
        ...prescription,
        ...drug,
      };
    });

    updatePrescriptions({ prescriptions, state });

    return {
      ...state,
      prescriptions,
    };
  }

  return state;
};

export const deletePrescription = (
  state: AppState,
  { payload }: Action
): AppState => {
  if (TypeGuards.hasIndex(payload)) {
    const prescriptions = state.prescriptions.filter(
      (p, i) => i !== payload.index
    );

    updatePrescriptions({ prescriptions, state });

    return {
      ...state,
      prescriptions,
    };
  }

  return state;
};

export const hasMailOrderPharmacy = (
  state: Partial<AppState> & Pick<AppState, "pharmacyType">
): boolean => {
  return [PharmacyType.MAIL, PharmacyType.MAIL_AND_RETAIL].includes(
    state.pharmacyType
  );
};

export const addPharmacy = (
  state: AppState,
  { payload: pharmacy }: Action
): AppState => {
  if (TypeGuards.isPharmacy(pharmacy)) {
    const isNewPharmacy = !state.pharmacies.find(p => p.npi === pharmacy.npi);
    return isNewPharmacy
      ? { ...state, pharmacies: [...state.pharmacies, pharmacy] }
      : state;
  }

  return state;
};

export const deletePharmacy = (
  state: AppState,
  { payload: npi }: Action
): AppState => {
  if (typeof npi === "string") {
    const existingPharmacyCount = state.pharmacies.length;
    const pharmacies = state.pharmacies.filter(p => p.npi !== npi);
    return pharmacies.length < existingPharmacyCount
      ? {
          ...state,
          pharmacies,
        }
      : state;
  }
  return state;
};

export const savePharmacies = (state: AppState): AppState => {
  if (hasGlobalSession(state)) {
    (async () => {
      const npis = state.pharmacies.map(p => p.npi);
      if (hasMailOrderPharmacy(state)) {
        npis.push("mail-order");
      }
      try {
        await saveBenePharmacies(npis);
      } catch (e) {
        logError(
          "Failed to save beneficiary pharmacies (AppStore/savePharmacies)",
          e as ApiError
        );
      }
    })();
  }
  return state;
};

export const setPharmacies = (
  state: AppState,
  { payload }: Action
): AppState => {
  const pharmacies = TypeGuards.hasPharmacies(payload)
    ? payload
    : state.pharmacies;

  return { ...state, pharmacies };
};

export const setMailOrderPharmacyNetworkStatus = (
  state: AppState,
  { payload }: Action
): AppState => {
  const mailOrderNetworkStatus = TypeGuards.isMailOrderPharmacyStatus(payload)
    ? payload
    : state.mailOrderNetworkStatus;
  return { ...state, mailOrderNetworkStatus };
};

export const updateLis = (
  state: AppState,
  { payload: lis }: Action,
  setFutureLis = false
): AppState => {
  if (!TypeGuards.isLIS(lis)) {
    return state;
  }

  let snpFilters: PlanSNPType[] = [];

  if (isDSNP(lis)) {
    snpFilters = [...(state.snpFilters || [])];
    if (!Object.values(snpFilters).includes(PlanSNPType.SNP_TYPE_NOT_SNP)) {
      snpFilters.push(PlanSNPType.SNP_TYPE_NOT_SNP);
    }

    if (
      !Object.values(snpFilters).includes(PlanSNPType.SNP_TYPE_DUAL_ELIGIBLE)
    ) {
      snpFilters.push(PlanSNPType.SNP_TYPE_DUAL_ELIGIBLE);
    }
  }

  return setFutureLis
    ? { ...state, futureLis: lis, snpFilters }
    : { ...state, lis, snpFilters };
};

export const updatePlanType = (
  state: AppState,
  { payload }: Action
): AppState => {
  const planType = TypeGuards.isPlanType(payload) ? payload : undefined;
  const defaultState = getDefaultState();

  return {
    ...state,
    planType,
    searchResultsFilters: defaultState.searchResultsFilters,
  };
};

/**
 * Updates the plan-specific network status for a pharmacy. Called whenever
 * `PharmacyNetworkStatus` is used, to prevent flash-of-incorrect-state issues
 * when switching between plans
 */
export const updatePharmacyNetworkStatus = (
  state: AppState,
  { payload }: Action
): AppState => {
  if (TypeGuards.isPharmacyInfo(payload)) {
    const pharmacies = state.pharmacies.map(p => {
      return p.npi === payload.npi
        ? { ...p, in_network: payload.in_network, preferred: payload.preferred }
        : p;
    });
    return {
      ...state,
      pharmacies,
    };
  }
  return state;
};

export const updateSearchResultsFiltersCarriers = (
  state: AppState,
  { payload }: Action
): AppState => {
  const insuranceCarriers = TypeGuards.hasInsuranceCarriers(payload)
    ? payload
    : [];

  return {
    ...state,
    searchResultsFilters: {
      ...state.searchResultsFilters,
      insuranceCarriers,
    },
  };
};

export const updateTrackedEvents = (
  state: AppState,
  { payload }: Action
): AppState => {
  const eventTrackingPayload = payload as EventTrackingPayload;
  const eventToRegister = TypeGuards.isRegisteredEvent(
    eventTrackingPayload?.event
  )
    ? eventTrackingPayload.event
    : null;
  if (eventToRegister && typeof eventTrackingPayload?.value !== "undefined") {
    return {
      ...state,
      trackedEvents: {
        ...state.trackedEvents,
        [eventToRegister]: eventTrackingPayload.value,
      },
    };
  }
  return state;
};
