import { IEPStatus, NewToMedicareAlertInfo, PlanType } from "../@types";
import { addMonths, addYears, lastDayOfMonth, startOfMonth } from "date-fns";
import { useLocation, matchPath } from "react-router-dom";
import { Location } from "history";
import routes from "../app/routes";
import URI from "urijs";
import { TranslationKey } from "./intlHooks";
import { useNewToMedicareAlertInfo } from "./context-hooks/useNewToMedicareAlertInfo";
import { useAppContext } from "./context-hooks/useAppContext";
import { hasGlobalSession } from "./loginHelpers";
import { parseSearchParams } from "./objectUtilities";
import { isMedicareAdvantageType, planTypeHasDrugCoverage } from "./planTypes";
import { getIEPStatusFromAPI } from "./iepStatusHelpers";

/**
 * Calculates the Initial Enrollment Period (IEP) for a bene
 * @param birthdate the birthdate
 * @returns the Initial Enrollment Period
 */
export const calculateIEP = (
  birthdate: Date
): {
  startDate: Date;
  endDate: Date;
} => {
  // offsets are in months
  let startOffset = -3;
  let endOffset = 3;

  if (birthdate.getDate() === 1) {
    // birthdate is the first day of the month
    startOffset = -4;
    endOffset = 2;
  }
  const MEDICARE_ELIGIBILITY_AGE = 65;
  const firstDay = startOfMonth(birthdate);
  const sixyFiveDate = addYears(firstDay, MEDICARE_ELIGIBILITY_AGE);
  const iepStart = addMonths(sixyFiveDate, startOffset);
  const iepEnd = lastDayOfMonth(addMonths(sixyFiveDate, endOffset));

  return {
    startDate: iepStart,
    endDate: iepEnd,
  };
};

type RouteKeys = keyof typeof routes;
type SummaryRouteKeys = keyof typeof routes.summary;
type MedigapRoutes = keyof typeof routes.medigap;
export type PossibleRoutes =
  | (typeof routes)[RouteKeys]
  | (typeof routes.summary)[SummaryRouteKeys]
  | (typeof routes.medigap)[MedigapRoutes];

/**
 * Simple wrapper over React Router's `match` helper, to determine whether the
 * current location matches (a) given route(s)
 * If there are two routes that are functionally equivalent for an LI bene coming
 * from the summary page or from elsewhere in the app, match against either of those
 * routes. E.g., `/summary/search-results` or `/search-results`
 */
export const isNtmAlertPathMatch = ({
  route,
  location,
}: {
  route: PossibleRoutes;
  location: Location;
}) => {
  const routeString = route as string;
  let alternateRoute = null;
  const liRoutePrefix = routes.summary.landingPage;
  // If there are two routes that are functionally equivalent for an LI bene coming from
  // the summary page or from elsewhere in the app, match against either of those
  // routes. E.g., `/summary/search-results` or `/search-results`
  const nestedRoute = !routeString.includes(liRoutePrefix)
    ? `${liRoutePrefix}${routeString}`
    : null;
  const unnestedRoute = routeString.includes(liRoutePrefix)
    ? routeString.replace(liRoutePrefix, "")
    : null;
  if (
    nestedRoute &&
    Object.values(routes.summary).some(route => route === nestedRoute)
  ) {
    alternateRoute = nestedRoute;
  }
  if (
    unnestedRoute &&
    Object.values(routes).some(route => route === unnestedRoute)
  ) {
    alternateRoute = unnestedRoute;
  }
  return [routeString, alternateRoute].some(
    route =>
      route &&
      !!matchPath(location.pathname, {
        path: route,
        exact: true,
        strict: true,
      })
  );
};

export const getIsSepPage = (location: Location) => {
  const uri = new URI(location.search);
  const searchParams = parseSearchParams(uri.search(true));
  return (
    isNtmAlertPathMatch({ route: routes.enroll, location }) &&
    searchParams.page === "Sep"
  );
};

export const getNeedsMedicareABAlert = ({
  hasPartAandB,
  newToMedicareResult,
  isLoggedIn,
  location,
  planType,
}: {
  hasPartAandB: NewToMedicareAlertInfo["hasPartAandB"] | undefined;
  newToMedicareResult:
    | NewToMedicareAlertInfo["newToMedicareResult"]
    | undefined;
  isLoggedIn: boolean;
  location: Location;
  planType: PlanType | undefined;
}): {
  bodyKey: TranslationKey;
  headingKey: TranslationKey;
  shouldShow: boolean;
  showAsError: boolean;
} => {
  let shouldShow = false;
  let showAsError = false;
  // default to Search Results keys
  let headingKey: TranslationKey =
    "ntm.alert.parts_ab_required.heading.search_results_page";
  let bodyKey: TranslationKey =
    "ntm.alert.parts_ab_required.body.search_results_page";

  switch (true) {
    case isNtmAlertPathMatch({ route: routes.summary.landingPage, location }):
      shouldShow = !hasPartAandB;
      headingKey = "ntm.alert.parts_ab_required.heading.summary_page";
      bodyKey = "ntm.alert.parts_ab_required.body.summary_page";
      break;
    case isNtmAlertPathMatch({ route: routes.searchResults, location }):
      shouldShow = isLoggedIn
        ? !!planType && isMedicareAdvantageType(planType) && !hasPartAandB
        : !!newToMedicareResult;
      break;
    case getIsSepPage(location):
      // Only needed on the Sep step of enrollment, when that step is rendered
      if (isLoggedIn) {
        shouldShow =
          !!planType && isMedicareAdvantageType(planType) && !hasPartAandB;
        showAsError = true;
        headingKey = "ntm.alert.parts_ab_required.heading.enrollment_sep_page";
        bodyKey = "ntm.alert.parts_ab_required.body.enrollment_sep_page";
      } else {
        shouldShow = !!newToMedicareResult;
      }
      break;
    case isNtmAlertPathMatch({ route: routes.medigap.plans, location }):
      shouldShow = isLoggedIn ? !hasPartAandB : !!newToMedicareResult;
      headingKey = "ntm.alert.parts_ab_required.heading.medigap";
      bodyKey = "ntm.alert.parts_ab_required.body.medigap";
      break;
    case isNtmAlertPathMatch({ route: routes.medigap.policies, location }):
      shouldShow = isLoggedIn ? !hasPartAandB : !!newToMedicareResult;
      headingKey = "ntm.alert.parts_ab_required.heading.medigap";
      bodyKey = "ntm.alert.parts_ab_required.body.medigap";
      break;
    default:
      break;
  }
  return { shouldShow, showAsError, headingKey, bodyKey };
};

export const useNeedsMedicareABAlert = () => {
  const {
    state,
    state: { planType },
  } = useAppContext();
  const newToMedicareAlertInfo = useNewToMedicareAlertInfo();
  const location = useLocation();
  const isLoggedIn = hasGlobalSession(state);
  return getNeedsMedicareABAlert({
    hasPartAandB: newToMedicareAlertInfo?.hasPartAandB,
    newToMedicareResult: newToMedicareAlertInfo?.newToMedicareResult,
    isLoggedIn,
    planType,
    location,
  });
};

/**
 * Rendering and content logic for Pard D LEP Alerts, for NtM initiative
 * @param args
 * @param args.isSepEnrollmentAlert - Whether to render special content on enrollment SEP section
 * @param args.isIepEnrollmentAlert - Whether to render special content on enrollment IEP section
 */
export const getAvoidPartDPenaltyAlertContent = ({
  iepStatus,
  isLoggedIn,
  isSepEnrollmentAlert = false,
  isIepEnrollmentAlert = false,
  location,
}: {
  iepStatus: IEPStatus | undefined;
  isLoggedIn: boolean;
  isIepEnrollmentAlert?: boolean;
  isSepEnrollmentAlert?: boolean;
  location: Location;
}): {
  bodyKey: TranslationKey;
  renderHeading: boolean;
} => {
  const isSepPage = getIsSepPage(location);
  let renderHeading = !isSepPage;
  // Alert body depends on LI/anon and iepStatus, default to generic for anon
  let bodyKey: TranslationKey = "ntm.alert.avoid_part_d_penalty.body.generic";
  if (isSepPage) {
    renderHeading = false;
    if (isSepEnrollmentAlert) {
      bodyKey = "ntm.alert.avoid_part_d_penalty.body.enrollment_sep";
    }
    if (isIepEnrollmentAlert) {
      bodyKey = "ntm.alert.avoid_part_d_penalty.body.enrollment_iep";
    }
  } else {
    if (isLoggedIn) {
      switch (iepStatus) {
        case IEPStatus.IEP_STATUS_WITHIN_IEP:
          bodyKey = "ntm.alert.avoid_part_d_penalty.body.within_iep";
          break;
        case IEPStatus.IEP_STATUS_PAST_IEP:
          bodyKey = "ntm.alert.avoid_part_d_penalty.body.past_iep";
          break;
        case IEPStatus.IEP_STATUS_BEFORE_IEP:
          bodyKey = "ntm.alert.avoid_part_d_penalty.body.before_iep";
          break;
      }
    }
  }
  return {
    bodyKey,
    renderHeading,
  };
};

/**
 * Hook providing rendering and content logic for Pard D LEP Alerts, for NtM initiative
 * @param args
 * @param args.isSepEnrollmentAlert - Whether to render special content on enrollment SEP section
 * @param args.isIepEnrollmentAlert - Whether to render special content on enrollment IEP section
 */
export const useAvoidPartDPenaltyAlertContent = ({
  isSepEnrollmentAlert = false,
  isIepEnrollmentAlert = false,
}: {
  isSepEnrollmentAlert?: boolean;
  isIepEnrollmentAlert?: boolean;
} = {}) => {
  const { state } = useAppContext();
  const isLoggedIn = hasGlobalSession(state);
  const location = useLocation();

  const newToMedicareAlertInfo = useNewToMedicareAlertInfo();
  return getAvoidPartDPenaltyAlertContent({
    iepStatus: newToMedicareAlertInfo?.iepStatus,
    isLoggedIn,
    isIepEnrollmentAlert,
    isSepEnrollmentAlert,
    location,
  });
};

// @TODO - New helper and hook added to handle rendering and content logic for
// these alerts. These and the associated tests should be removed if no code is
// referencing. Existing test logic should be re-used (if it's correct) and expanded
// for tests of the new helper, `getAvoidPartDPenaltyAlert`
export const shouldShowAvoidPartDPenaltyAlert = ({
  isLoggedIn,
  location,
  newToMedicareAlertInfo,
  planType,
}: {
  isLoggedIn: boolean;
  location: Location;
  newToMedicareAlertInfo: NewToMedicareAlertInfo | undefined;
  planType: PlanType | undefined;
}): boolean => {
  let shouldShow = false;
  const isSepPage = getIsSepPage(location);
  const {
    hasMedigap,
    hasPartAandB,
    hasPDPPlan,
    hasMAPDPlan,
    newToMedicareResult,
  } = newToMedicareAlertInfo || {
    hasMedigap: false,
    hasPartAandB: false,
    hasPDPPlan: false,
    hasMAPDPlan: false,
    newToMedicareResult: undefined,
  };
  const isSummaryPage = isNtmAlertPathMatch({
    route: routes.summary.landingPage,
    location,
  });
  const isPlanDetailsPage = isNtmAlertPathMatch({
    route: routes.planDetails,
    location,
  });
  const isSearchResults = isNtmAlertPathMatch({
    route: isLoggedIn ? routes.summary.searchResults : routes.searchResults,
    location,
  });

  const planForEnrollmentHasDrugCoverage =
    !!planType && planTypeHasDrugCoverage(planType);

  if (isSepPage) {
    shouldShow =
      planForEnrollmentHasDrugCoverage &&
      (!isLoggedIn || (!hasPDPPlan && !hasMAPDPlan));
  } else {
    if (isLoggedIn) {
      const shouldShowOnSummaryPage =
        !hasPDPPlan &&
        !hasMAPDPlan &&
        ((hasMedigap && hasPartAandB) ||
          (!hasMedigap && hasPartAandB) ||
          (!hasMedigap && !hasPartAandB));
      shouldShow = isSummaryPage
        ? shouldShowOnSummaryPage
        : (isSearchResults || isPlanDetailsPage) && !hasMAPDPlan && !hasPDPPlan;
    } else {
      shouldShow =
        !!newToMedicareResult &&
        (isSearchResults ||
          (isPlanDetailsPage && planForEnrollmentHasDrugCoverage));
    }
  }
  return shouldShow;
};

export const useShowAvoidPartDPenaltyAlert = (planType?: PlanType): boolean => {
  const { state } = useAppContext();

  const newToMedicareAlertInfo = useNewToMedicareAlertInfo();
  const isLoggedIn = hasGlobalSession(state);
  const location = useLocation();
  return shouldShowAvoidPartDPenaltyAlert({
    isLoggedIn,
    location,
    newToMedicareAlertInfo,
    planType,
  });
};

/**
 * simple hook to check IEP status.
 * This could be extended to include other age related logic.
 */
export function useIEPStatus() {
  const {
    state: { beneficiary },
  } = useAppContext();
  const { medicare_info } = beneficiary || {};
  const { iep_end_date, iep_start_date } = medicare_info || {};

  const iepStatus =
    iep_start_date && iep_end_date
      ? getIEPStatusFromAPI(iep_start_date, iep_end_date)
      : undefined;

  return {
    iepStatus,
  };
}
