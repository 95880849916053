import { useQuery } from "@tanstack/react-query";
import { QueryKey, StaleTime } from "./types";
import { get } from "../../api";
import { useMctFlags } from "../flag-hooks/useMctFlags";

type Statuses<T extends string = string> = Record<T, "healthy" | "off">;
type DbVersions = {
  app_db_version: number;
  db_version: number;
  db_name: string;
};

type BeneDbInfo = DbVersions & {
  statuses: Statuses<
    "bedap-kong" | "bedap-v4" | "database" | "hpms" | "message-center"
  >;
};

type DrugDbInfo = {
  statuses: Statuses;
  versions: Record<string, number>;
  app_db_version: number;
  db_created_dates: Record<string, string>;
  db_names: Record<string, string>;
  db_submission_windows: Record<string, number>;
};

type PlanDbInfo = DbVersions & {
  statuses: Statuses<"database" | "mapbox">;
  db_created_date: string;
};

interface ApiVersionResponse {
  commit: string; // ex. "8ba225be"
  release: string; // ex. "2.4.0"
  planinfo: PlanDbInfo;
  druginfo: DrugDbInfo;
  beneinfo: BeneDbInfo;
  launchDarklyStatus: string;
  errors: unknown[];
  request_id: string;
}

/**
 * returns backend version information
 */
export const useBackendVersions = () => {
  // * Flags
  const { feDatabaseStaleTimeMinutes } = useMctFlags();
  const databaseStaleTime = feDatabaseStaleTimeMinutes * 60 * 1000 || 0;

  // * Queries
  const { data, isLoading, failureCount } = useQuery({
    queryKey: [QueryKey.BackendVersions],
    queryFn: () => {
      return (async () => {
        const res = await get("/status", {});
        const apiRes: ApiVersionResponse =
          res.body as unknown as ApiVersionResponse;
        return apiRes;
      })();
    },
    select: data => ({
      beneInfo: data.beneinfo,
      apiCommit: data.commit,
      drugInfo: data.druginfo,
      planInfo: data.planinfo,
      apiRelease: data.release,
    }),
    staleTime: databaseStaleTime,
  });

  const planDbTimestamp = data?.planInfo.db_created_date || "";
  /**
   * regardless of the properties on `drugInfo.db_created_dates`,
   * this will be the latest timestamp of both years
   */
  const drugDbTimestamp = (() => {
    const timestamps = data?.drugInfo.db_created_dates;
    if (!timestamps) return "";
    const timestampValues = Object.values(timestamps);
    return timestampValues.sort()[timestampValues.length - 1] || "";
  })();

  /**
   * helper variable so that all data that is based on these backend versions can operate with the same logic.
   * In this case, these endpoints will be Infinite fresh
   * (unless the `status` endpoint fails, in which case we will use the value set via flag to mimic a similar refresh rate)
   */
  const staleTime: number = data ? StaleTime.Infinite : databaseStaleTime;

  return {
    /** full object containing all back end version data */
    beVersions: data,
    planDbTimestamp,
    drugDbTimestamp,
    isLoading,
    /** stale time set to Infinity when we have valid backend version data.
     * Fallback to value from flag in case the `status`endpoint fails us (default: 10min) */
    staleTime,
    /**
     * will be true if the query status (for '/status' endpoint) is 'success'
     * or if this has errored out at least once.
     * Since we have a fallbacks we want to make use of, this is more reliable than using `isLoading`
     * at a high level (in AppContent.tsx)
     */
    hasAttempted: !!(data || failureCount > 0),
  };
};
