import {
  UserLanguage,
  FullPlanId,
  MctToastType,
  PlanDetailsPageSection,
  IdParts,
  OecStatus,
  SearchResultPlan,
} from "../@types";
import routes, { beneLandingAndCallbackRoutes } from "../app/routes";
import URI from "urijs";
import { RouteComponentProps, matchPath, useHistory } from "react-router-dom";
import { useEffect } from "react";
import { medigapPath, planComparePath } from "./CONSTANTS";
import { getPageNameFromPathName } from "../helpers/urlHelpers";
import { useSearchParams } from "./routing-hooks/useSearchParams";
import { useAppContext } from "./context-hooks/useAppContext";
import { makePlanLongId } from "./objectUtilities";

/**
 * @deprecated consider using `useUrlWithSearchParams` instead */
export const useUrlWithStateParams = (baseRoute: string) => {
  const {
    state: { year, language },
  } = useAppContext();

  return `${baseRoute}?year=${year}&lang=${language}`;
};

export const useUrlWithSearchParams = (baseRoute: string) => {
  const { selectedLanguage, selectedYear } = useSearchParams(true);

  return `${baseRoute}?year=${selectedYear}&lang=${selectedLanguage}`;
};

export const getPlanDetailsStarRatingsRoute = (
  plan: FullPlanId,
  language: UserLanguage,
  year: string
): string => {
  const baseRoute = routes.planDetails.replace(":id", makePlanLongId(plan));
  return `${baseRoute}?year=${year}&lang=${language}#${PlanDetailsPageSection.STAR_RATINGS}`;
};

export const getToastRoute = (
  currentRoute: string,
  toastType: MctToastType
): string => {
  const curr = new URI(currentRoute);
  curr.addQuery("mctt", toastType);

  return curr.toString();
};

export const getRouteName = (r: string): string => {
  r = r.replace(/\/MEDIGAP_PLAN_TYPE_.+/, "");
  r = r.replace(/\/\d.+/, "");
  r = r.replace(/states\/[A-Z]{2}/, "states/");

  const routeNames = {
    [routes.coverageWizard.info]: "Coverage Wizard - Info",
    [routes.coverageWizard.landingPage]: "Coverage Wizard - Landing",
    [routes.coverageWizard.options]: "Coverage Wizard - Options",
    [routes.pap.landingPage]: "PAP - Home",
    [routes.pap.plans.replace("/:drug", "")]: "PAP - Results",
    [routes.pace.landingPage]: "PACE - Landing",
    [routes.pace.plans.replace(":state", "")]: "PACE - Plans",
    [routes.medigap.landingPage]: "Medigap - Find a Policy",
    [routes.medigap.planDetails.replace("/:medigapPlanType", "")]:
      "Medigap - Plan Details",
    [routes.medigap.plans]: "Medigap - Search Results",
    [routes.medigap.policies.replace("/:medigapPlanType", "")]:
      "Medigap - Policies",
    [routes.managePrescriptions]: "Find Plans - Add Drugs",
    [routes.summary.managePrescriptions]: "Find Plans - My Saved Drugs",
    [routes.prescriptionsList]: "Find Plans - Add Drugs",
    [routes.pharmacy]: "Find Plans - Add Pharmacy",
    [routes.summary.selectPharmacies]: "Find Plans - Add Pharmacy",
    [routes.summary.managePharmacies]: "Find Plans - My Saved Pharmacies",
    [routes.searchResults]: "Find Plans - Search Results",
    [routes.summary.searchResults]: "Find Plans - Search Results",
    [routes.questionRouting]: "Find Plans - Enter Your Information",
    [routes.lisQuestions]: "Find Plans - Extra Help",
    [routes.summary.lisQuestions]: "Find Plans - Extra Help",
    [routes.drugSearchPreferences]:
      "Find Plans - Tell us your search preferences",
    [routes.summary.drugSearchPreferences]:
      "Find Plans - Tell us your search preferences",
    [routes.enroll]: "OEC - Enrollment",
    [routes.planDetails.replace("/:id", "")]: "Find Plans - Plan Details",
    [routes.sanctionedPlans]: "Find Plans - Sanctioned Plans",
    [routes.spap.landingPage]: "SPAP - Home",
    [routes.spap.plans.replace(":state", "")]: "SPAP - Results",
    [routes.pdePage]: "Find Plans - Add recently filled drugs",
    [routes.summary.pdePage]: "Find Plans - Add recently filled drugs",
    [routes.summary.landingPage]: "Find Plans - Your Summary",
    [routes.anonLanding]: "Find Plans - Landing",
  };

  return routeNames[r] || getPageNameFromPathName(r);
};

export const isFromMedigap = (url: string): boolean => {
  return url.includes(medigapPath);
};

export const routeMatcher = ({
  route,
  hash,
}: {
  route: string;
  hash: string;
}): boolean =>
  !!matchPath(hash, {
    path: route,
    exact: true,
    strict: false,
  });

/**
 * Helper to redirect traffic to the correct path and client route
 *
 * Akamai sends all traffic for /plan-compare as well as /medigap-supplemental-insurance-plans
 * to our app which means both will work with all our routes. but we want to keep the medigap
 * and mct routes aligned with their respective paths
 */
export const getRedirectForMedigapOrPlanFinder = (url: URL): string | null => {
  const { origin, pathname, hash } = url;
  const pathStripRegEx = /^\/([^/]*)[/preview/\d*/]*/gm;
  const strippedPath = pathname.replace(pathStripRegEx, "$1");
  const originalPathNameNoTrailingSlash =
    pathname.charAt(pathname.length - 1) !== "/";

  // Remove the #, query string, and additional "/" after m/route
  const formattedHash = hash.replace("#", "").replace(/\?.+/gm, "");
  // Make an alternate formattedHash with the `/m` route prefix to test whether
  // this is a valid Medigap route, for cases where an otherwise valid Medigap
  // URL is missing the `/m` prefix
  const medigapFormattedHash = formattedHash.startsWith("/m")
    ? formattedHash
    : `/m${formattedHash}`;

  const getAlternatePath = (pathname: string) =>
    new RegExp(medigapPath).test(pathname)
      ? pathname.replace(medigapPath, planComparePath)
      : pathname.replace(planComparePath, medigapPath);

  const isMedigapRouteMatch = Object.values(routes.medigap).some(route =>
    routeMatcher({ route, hash: medigapFormattedHash })
  );

  const isPlanCompareRouteMatch = Object.entries(
    routes as Record<string, string | Record<string, string>>
  ).some(([routeName, routeValue]) => {
    if (routeName === "medigap") {
      return false;
    } else {
      if (typeof routeValue === "string") {
        return routeMatcher({ route: routeValue, hash: formattedHash });
      } else {
        return Object.values(routeValue).some(subRouteValue => {
          return routeMatcher({
            route: subRouteValue,
            hash: formattedHash,
          });
        });
      }
    }
  });

  // Build a new hash to use for medigap paths, if needed
  // If the original hash already contains the base Medigap client-side route (`/m`),
  // this should just be passed through
  let hashToUseForMedigap = hash;

  // If there is no hash, the base client-side route should be added
  if (hash === "") {
    hashToUseForMedigap = "#/m";
  } else if (!/#\/m/.test(hash)) {
    // if the `/m` isn't already appended, add it to the existing hash, preserving
    // the rest of the original hash value
    hashToUseForMedigap = hash.split("#/").join("#/m/");
  }

  // The default `null` here will not redirect. That's the baseline condition
  let redirectUrl = null;

  // Redirect logic
  if (
    formattedHash === "/" ||
    formattedHash === "" ||
    /preview\/d+\/{0,1}$/.test(formattedHash)
  ) {
    // Redirect to correct root route
    const hashToUse = pathname.includes(medigapPath)
      ? hashToUseForMedigap
      : hash;
    const newUrl = `${origin}${pathname}${
      originalPathNameNoTrailingSlash ? "/" : ""
    }${hashToUse}`;
    const newUrlObj = new URL(newUrl);
    // Only redirect if the assembled URL is different from the one passed in to this fn
    if (!(newUrlObj.pathname === pathname && newUrlObj.hash === hash)) {
      redirectUrl = newUrl;
    }
  } else if (strippedPath === medigapPath && isPlanCompareRouteMatch) {
    // This is a plan-compare route, so redirect to Plan Compare path
    redirectUrl = `${origin}${getAlternatePath(pathname)}${hash}`;
  } else if (
    strippedPath === planComparePath &&
    isMedigapRouteMatch &&
    // There is at least one case where a Medigap route and plan-compare
    // route will both be matched, if the route is prefixed for testing
    // with Medigap routes. In this case, we don't want to redirect
    !isPlanCompareRouteMatch
  ) {
    // Redirect medigap routes to Medigap path
    redirectUrl = `${origin}${getAlternatePath(
      pathname
    )}${hashToUseForMedigap}`;
  } else if (
    strippedPath === medigapPath &&
    isMedigapRouteMatch &&
    medigapFormattedHash !== formattedHash
  ) {
    // Redirect because the Medigap route prefix needs to be re-appended
    redirectUrl = `${origin}${pathname}${hashToUseForMedigap}`;
  } else if (strippedPath === medigapPath && !isMedigapRouteMatch) {
    // This is an unknown medigap route, so just go to to Medigap landing
    redirectUrl = `${origin}${pathname}#/m`;
  } else if (strippedPath === planComparePath && !isPlanCompareRouteMatch) {
    redirectUrl = `${origin}/${planComparePath}`;
  }
  return redirectUrl;
};

/**
 * Get the first segment of a route path, no slashes and no params
 * @param path - A path listed in app routes, which may contain slash-delimited
 *               segments and params
 * @returns - The first segment of the path, only
 */
export const getBasePath = (path: string): string =>
  path.replace(/^\/*([\d|\w|\-|_]*).*$/, (_match, p1) => p1);

/**
 * Determine whether the current route should display the Consistent Header
 * @param location - A location object passed from a routed component, e.g.
 * @param hideGlobalHeaderPaths - An array of route paths for routes that shouldn't
 *                                show the Global (Consistent) Header
 * @returns - Whether the current route should display the CH
 */
export const shouldDisplayGlobalHeader = (
  location: Partial<Location>,
  hideGlobalHeaderPaths: string[]
): boolean =>
  !hideGlobalHeaderPaths
    .map(getBasePath)
    .some(path => location?.pathname?.includes(path));

/**
 * Create a link to plan details interpolating the :id variable with a plan long id
 * and adding needed query parameters
 * @param plan
 * @param language
 * @returns A formatted link to plan details
 */
export const makePlanDetailsUrl = ({
  plan,
  yearValue = "contract_year",
  language,
}: {
  plan: IdParts | OecStatus;
  yearValue?: "contract_year" | "coverage_year" | "year";
  language: UserLanguage;
}): string =>
  `${routes.planDetails.replace(
    ":id",
    makePlanLongId(plan)
  )}?lang=${language}&year=${plan[yearValue]}`;

/**
 * @TODO - Re-evaluate with MCT-7039
 * GlobalSessionHandler passes a concatenated location.path + location.search string
 * in the property `from` on `history.location.state`.
 */
export const sendToFromIfExists = (history: RouteComponentProps["history"]) => {
  if (history.location.state) {
    const { from } = history.location.state as {
      from?: string;
    };
    if (from) {
      history.replace(from);
    }
  }
};

export const createComparePlansUri = ({
  planList,
  fips,
}: {
  planList: SearchResultPlan[];
  fips: string;
}) => {
  const compareURI = new URI(routes.comparePlans);
  compareURI.addSearch(
    "plans",
    planList.map(plan => makePlanLongId(plan))
  );
  compareURI.addSearch("fips", fips);
  return compareURI;
};

/**
 * @returns the hash-routed pathname prefixed by a forward slash, which matches
 * a value from `routes`
 */
export const getHashPathName = () => {
  const hash = window.location.hash;
  return hash.replace("#", "").slice(0, hash.indexOf("?") - 1);
};

/**
 * @returns true if current location is a bene landing or callback route
 */
export const getIsBeneLandingOrCallbackRoute = () => {
  const hashPathName = getHashPathName();
  return beneLandingAndCallbackRoutes.includes(hashPathName);
};

/**
 * a useEffect hook which will redirect based on a condition.
 * @example // will redirect to the landing page if the user is logged in.
 * useRedirect({condition: isLoggedIn, route: Routes.summary.landingPage})
 */
export function useRedirect({
  condition,
  route,
  replace,
}: {
  condition: unknown;
  route: string;
  replace?: boolean;
}) {
  const history = useHistory();

  useEffect(() => {
    if (condition) {
      if (replace) {
        history.replace(route);
      } else {
        history.push(route);
      }
    }
  }, [condition, history, route, replace]);
}

export const getLandingPageRoutes = (
  currentYear: number
): { [x: string]: string } => {
  const { questionRouting, summary } = routes;
  const loggedInHomeRoute = summary.landingPage;
  const questionsRouteForThisYear = `${questionRouting}?year=${currentYear}`;
  const questionsRouteForNextYear = `${questionRouting}?year=${
    currentYear + 1
  }`;
  const beneRouteForThisYear = `${loggedInHomeRoute}?year=${currentYear}`;
  const beneRouteForNextYear = `${loggedInHomeRoute}?year=${currentYear + 1}`;

  return {
    questionsRouteForThisYear,
    questionsRouteForNextYear,
    beneRouteForNextYear,
    beneRouteForThisYear,
  };
};
