import React, { ReactNode, HTMLProps } from "react";
import { Redaction } from "../@types";
import { useTranslate } from "../helpers/intlHooks";
import {
  shouldSuppressDrugPricing,
  shouldSuppressPlanBenefits,
} from "../helpers/planHelpers";
import classnames from "classnames";

export const suppressedBenefitTextKey = "suppressed_plan_benefit.contact_plan";

type SuppressionHandlerCheckDrugPricing =
  | {
      checkShouldSuppressDrugPricing?: boolean;
      suppressOnlyDrugPricing?: never;
    }
  | {
      checkShouldSuppressDrugPricing?: never;
      suppressOnlyDrugPricing?: boolean;
    };

export type SuppressionHandlerProps = {
  children: ReactNode;
  redactions: Redaction[];
  suppress?: boolean;
  suppressionWrapperProps?: Omit<HTMLProps<HTMLDivElement>, "children">;
} & SuppressionHandlerCheckDrugPricing;

/**
 * Renders a `div` that's set to `display: inline`
 * - Can contain `block` or `inline` content
 * @param children
 * @param suppress - optional, and overrides any internal checks for whether
 *                   benefits should be suppressed
 * @param suppressionWrapperProps - wraps suppression messaging in a `div` with these props
 */
const SuppressionHandler = ({
  checkShouldSuppressDrugPricing,
  children,
  redactions,
  suppress,
  suppressOnlyDrugPricing,
  suppressionWrapperProps = {},
  ...props
}: HTMLProps<HTMLDivElement> & SuppressionHandlerProps) => {
  const t = useTranslate();
  const { className, ...rest } = props;

  let shouldSuppressBenefits = false;
  const suppressOverride = typeof suppress === "boolean";

  if (suppressOverride) {
    // Use boolean value alone to control benefit value suppression
    // Note - our build fails here unless this is cast as a boolean, even though
    // it logically is in this conditional block
    shouldSuppressBenefits = suppress as boolean;
  } else {
    if (suppressOnlyDrugPricing) {
      // Check only whether drug pricing should be suppressed
      shouldSuppressBenefits = shouldSuppressDrugPricing(redactions);
    } else {
      // Check whether either plan benefits or both plan benefits and drug pricing
      // should be suppressed
      shouldSuppressBenefits = checkShouldSuppressDrugPricing
        ? shouldSuppressPlanBenefits(redactions) ||
          shouldSuppressDrugPricing(redactions)
        : shouldSuppressPlanBenefits(redactions);
    }
  }

  const hasSuppressionWrapper = Object.keys(suppressionWrapperProps).length > 0;
  // If suppression message should show and props for a wrapping component were
  // passed in, wrap messaging in a `div` with these props
  const WrapperEl =
    hasSuppressionWrapper && shouldSuppressBenefits
      ? ({ children }: { children: ReactNode }) => (
          <div {...{ ...suppressionWrapperProps, children }} />
        )
      : React.Fragment;
  return (
    <WrapperEl>
      <div
        className={classnames("mct-c-benefit", {
          "mct-c-benefit--is-suppressed": shouldSuppressBenefits,
          [className || ""]: !shouldSuppressBenefits,
        })}
        {...rest}
      >
        {shouldSuppressBenefits ? t(suppressedBenefitTextKey) : children}
      </div>
    </WrapperEl>
  );
};

export default SuppressionHandler;
