export const formatStringPhone = (phone: string): string =>
  `(${phone.substring(0, 3)}) ${phone.substring(3, 6)}-${phone.substring(6)}`;

export const toTitleCase = (str: string): string => {
  if (!str) {
    return "";
  }
  const knownAcronynms: Record<string, boolean> = {
    cvs: true,
    "": true,
    llc: true,
  };
  return str
    .toLowerCase()
    .split(" ")
    .map(word =>
      knownAcronynms[word]
        ? word.toUpperCase()
        : word.replace(word[0], word[0].toUpperCase())
    )
    .join(" ");
};

export const normalizeStringCase = (str: string): string =>
  str
    .split(" ")
    .map(s => toTitleCase(s))
    .join(" ");

export const removeHtmlTags = (input: string): string => {
  // Use a regular expression to match HTML tags
  return input.replace(/<[^>]*>/g, "");
};
