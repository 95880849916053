export interface UpdateAttributeParams {
  el: HTMLElement;
  name: string;
  value: string;
  remove?: boolean;
}

type AddOrRemoveAttributeParams = Omit<UpdateAttributeParams, "remove">;

const updateAttributeValue = ({
  el,
  name,
  value,
  remove = false,
}: UpdateAttributeParams) => {
  const startingValue = el.getAttribute(name);
  const add = !remove;
  const valuesToAddOrRemove = value.split(" ");
  const filteredExistingValues =
    null !== startingValue
      ? startingValue
          .split(" ")
          .filter(val => !valuesToAddOrRemove.includes(val))
      : null;
  let newValues = [];
  if (filteredExistingValues) {
    newValues = add
      ? [...filteredExistingValues, ...valuesToAddOrRemove]
      : filteredExistingValues;
  } else {
    newValues = add ? valuesToAddOrRemove : [];
  }
  const newValue =
    newValues.length === 0
      ? null
      : newValues.length === 1
      ? newValues[0]
      : newValues.join(" ");
  if (newValue) {
    el.setAttribute(name, newValue);
  } else {
    el.removeAttribute(name);
  }
};

/**
 * Removes an attribute or one or more values from an attribute with multiple values
 * that accepts space-delimited strings
 * @example removeAttributeValue({el: divElRef, name: "data-my-attr", value: "foo bar" })
 */
export const removeAttributeValue = ({
  el,
  name,
  value,
}: AddOrRemoveAttributeParams) =>
  updateAttributeValue({ el, name, value, remove: true });

/**
 * Adds an attribute or one or more values to an existing attribute that accepts
 * space-delimited strings
 * @example addAttributeValue({el: divElRef, name: "data-my-attr", value: "foo bar" })
 */
export const addAttributeValue = ({
  el,
  name,
  value,
}: AddOrRemoveAttributeParams) => updateAttributeValue({ el, name, value });

/**
 * Removes and adds one or more values on an existing attribute that accepts
 * space-delimited strings
 * @example replaceAttributeValue({el: divElRef, name: "data-my-attr", value: "foo bar", removeValue: "baz gah" })
 */
export const replaceAttributeValue = ({
  el,
  name,
  value,
  removeValue,
}: AddOrRemoveAttributeParams & { removeValue: string }) => {
  removeAttributeValue({ el, name, value: removeValue });
  addAttributeValue({ el, name, value });
};
