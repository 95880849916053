import {
  PlanType,
  DrugCoverageFilterOption,
  SearchResultPlan,
  PlanCategoryType,
  PlanSNPType,
  Plan,
} from "../@types";
import { TranslationKey } from "./intlHooks";

export const isMaPlanType = (t: PlanType): boolean => {
  return t === PlanType.MA;
};

export const isMapdPlanType = (t: PlanType): boolean => {
  return t === PlanType.MAPD;
};

// Group Medicare Advantage plans, with and without drugs, and treat as single "plan type"
export const isMedicareAdvantageType = (t: PlanType): boolean => {
  return isMaPlanType(t) || isMapdPlanType(t);
};

// Group MAPD and PDP plans (with drug coverage), and treat as single "plan type"
export const planTypeHasDrugCoverage = (t: PlanType): boolean =>
  isMapdPlanType(t) || t === PlanType.PDP;

export const isMMPlanCategory = (c: PlanCategoryType) =>
  c === PlanCategoryType.MEDICARE_MEDICAID_PLAN;

export const stringIsValidPlanType = (str: string): boolean => {
  return Object.values(PlanType).includes(str as PlanType);
};

export const isPdPlanType = (t: PlanType): boolean => {
  return !isMedicareAdvantageType(t) && t !== PlanType.MEDIGAP;
};

export const getOppositePlanType = (t: PlanType): PlanType => {
  return isMedicareAdvantageType(t) ? PlanType.PDP : PlanType.MAPD;
};

export const getPlanTypeParam = (
  t: PlanType,
  filterOption?: DrugCoverageFilterOption
): PlanType[] | PlanType => {
  if (
    t !== PlanType.PDP &&
    filterOption === DrugCoverageFilterOption.INCLUDES
  ) {
    return PlanType.MAPD;
  } else if (
    t !== PlanType.PDP &&
    filterOption === DrugCoverageFilterOption.EXCLUDES
  ) {
    return PlanType.MA;
  } else if (t === PlanType.MA || t === PlanType.MAPD) {
    return [PlanType.MA, PlanType.MAPD];
  } else {
    return PlanType.PDP;
  }
};

export const arePlansComparable = (
  planTypeA: PlanType,
  planTypeB: PlanType
): boolean => {
  const comparablePlans = [PlanType.MA, PlanType.MAPD];
  if (planTypeA === planTypeB) {
    return true;
  }
  if (
    comparablePlans.includes(planTypeA) &&
    comparablePlans.includes(planTypeB)
  ) {
    return true;
  }
  return false;
};

export const shouldHideCompare = ({
  isCurrentPlan = false,
  planType,
  plan,
}: {
  isCurrentPlan?: boolean;
  planType?: PlanType;
  plan: SearchResultPlan;
}) =>
  isCurrentPlan && !!planType && !arePlansComparable(plan.plan_type, planType);

/**
 * Takes a plan and returns translation keys for `planType` and `snpType`
 * These will both be undefined for OM
 * Keys were originally targeted to Plan Details, but unless requirements specify
 * otherwise, can be reused elsewhere
 */
export const getPlanTypeTranslationKeys = (plan: Plan | SearchResultPlan) => {
  let planTypeKey: TranslationKey | undefined;
  let snpTypeKey: TranslationKey | undefined;

  // we don't display the plan type or the snp type for original medicare
  if (plan.category !== PlanCategoryType.ORIGINAL_MEDICARE) {
    if (isMaPlanType(plan.plan_type)) {
      planTypeKey = "plan_details.MA";
    } else if (isMapdPlanType(plan.plan_type)) {
      planTypeKey = "plan_details.MAPD";
    } else {
      planTypeKey = "plan_details.PDP";
    }

    if (plan.category === PlanCategoryType.REGIONAL_PPO) {
      planTypeKey = "plan_details.regional_ppo";
    } else if (plan.category === PlanCategoryType.MEDICARE_MEDICAID_PLAN) {
      planTypeKey = "plan_details.medicare_medicaid_plan";
    }

    if (plan.snp_type === PlanSNPType.SNP_TYPE_DUAL_ELIGIBLE) {
      snpTypeKey = "plan_details.dual_eligible";
    } else if (plan.snp_type === PlanSNPType.SNP_TYPE_INSTITUTIONAL) {
      snpTypeKey = "plan_details.institutional";
    } else if (plan.snp_type === PlanSNPType.SNP_TYPE_CHRONIC_OR_DISABLING) {
      snpTypeKey = "plan_details.chronic_condition";
    }
  }

  return { planTypeKey, snpTypeKey };
};
