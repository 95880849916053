import React, { useContext } from "react";
import { useLocation } from "react-router-dom";
import { Card, SkipNav } from "@cmsgov/ds-medicare-gov";
import {
  ActionType,
  PharmacyType,
  PlanCategoryType,
  SearchResultPlan,
} from "../../@types";
import PlanCardHeader from "./PlanCardHeader";
import PlanCardActions from "./PlanCardActions";
import PlanBenefits from "./PlanBenefits";
import MonthlyPremium from "./MonthlyPremium";
import OtherCosts from "./OtherCosts";
import Drugs from "./Drugs";
import Pharmacies from "./Pharmacies";
import Deductible from "./Deductible";
import Copays from "./Copays";
import YearlyCost from "./YearlyCost";
import { CollapsibleBox, ServiceAreaReductionNotice } from "..";
import routes from "../../app/routes";
import {
  Ga4Event,
  AnalyticsActionType,
} from "../../app/contexts/Analytics/types";
import classNames from "classnames";
import { MedicareGovAnchor } from "../MedicareGovAnchor";
import { useIntersectionObserver } from "usehooks-ts";
import { useDrugCosts } from "../../helpers/query-hooks/useDrugCosts";
import { TranslationKey, useTypedTranslate } from "../../helpers/intlHooks";
import { useShouldShowSARNotice } from "../ServiceAreaReductionNotice/sarHelpers";
import { isCoverageSameAsPlan as isRolloverPlan } from "../../helpers/planHelpers";
import { useAppContext } from "../../helpers/context-hooks/useAppContext";
import { PreviewContext } from "../../helpers/PreviewContext";
import {
  isMedicareAdvantageType,
  shouldHideCompare,
} from "../../helpers/planTypes";

interface PlanCardProps {
  dataTestId?: string;
  handleAddToCompare?: (plan: SearchResultPlan) => void;
  handleRemoveFromCompare?: (removeId: number | string) => void;
  /**
   * Tells the component whether to fetch `drugCostInfo` (`false`, default) or
   * use `plan` data (`true`) for cost-related output.
   **/
  hasCostsFromPlan?: boolean;
  isCurrentPlan?: boolean;
  labelId?: TranslationKey;
  plan: SearchResultPlan;
}

const PlanCard: React.FunctionComponent<PlanCardProps> = ({
  dataTestId = "",
  handleAddToCompare,
  handleRemoveFromCompare,
  hasCostsFromPlan = false,
  isCurrentPlan,
  labelId = "plan_card.your_current_plan",
  plan,
}) => {
  const t = useTypedTranslate();
  const {
    state: { beneficiary, planType, compareList, nextYearCoverage },
    dispatch,
  } = useAppContext();
  const previewMode = useContext(PreviewContext);
  const { isIntersecting, ref } = useIntersectionObserver();
  const disableDrugCosts =
    (!isIntersecting || hasCostsFromPlan) && !previewMode;
  const { drugCostInfo } = useDrugCosts(plan, {
    disabled: disableDrugCosts,
  });
  const showSARNotice = useShouldShowSARNotice();

  const hideCompareButton = shouldHideCompare({
    isCurrentPlan,
    planType,
    plan,
  });
  const { search } = useLocation();
  const pdes = beneficiary ? beneficiary.pdes : [];
  const drugSelectionRouteBase =
    pdes && pdes.length > 0 ? routes.pdePage : routes.managePrescriptions;
  const drugSelectionRoute = `${drugSelectionRouteBase}${search}`;
  const compareAnchorTarget = "plan-compare-footer-sticky-tray-heading";

  const onGoToDrugSelection = (): void => {
    dispatch({
      type: AnalyticsActionType.SEND_GA4_EVENT,
      settings: {
        event_name: Ga4Event.VIEW_DRUG_LIST,
        text: "Add your prescription drug",
      },
    });
    dispatch({
      type: ActionType.UPDATE_PHARMACY_TYPE,
      payload: PharmacyType.MAIL_AND_RETAIL,
    });
  };

  return (
    <Card
      className={classNames("PlanCard", plan.plan_type, {
        "preview-mode": previewMode,
      })}
    >
      {isCurrentPlan ? (
        <div
          className="PlanCard__current_plan_banner"
          data-cy="plan-card-current-plan-banner"
          data-testid={`${dataTestId}-banner`}
        >
          {t(labelId)}
        </div>
      ) : null}
      <PlanCardHeader plan={plan} />

      {showSARNotice && isRolloverPlan(nextYearCoverage, plan) && (
        <ServiceAreaReductionNotice
          stackContent={true}
          className="ds-u-margin-bottom--2"
        />
      )}

      {plan.category === PlanCategoryType.MSA && (
        <p>
          <MedicareGovAnchor urlKey="whatIsMedicareMSA">
            {t("msa_plan.how_does_msa_work")}
          </MedicareGovAnchor>
        </p>
      )}
      <div ref={ref} className="PlanCard__body">
        <div>
          <div className="PlanCard__flex_left">
            <div>
              <MonthlyPremium plan={plan} />
              {!previewMode && (
                <YearlyCost
                  drugCostInfo={drugCostInfo}
                  plan={plan}
                  hasCostsFromPlan={hasCostsFromPlan}
                />
              )}
              {isMedicareAdvantageType(plan.plan_type) ? (
                <OtherCosts plan={plan} />
              ) : (
                <Deductible plan={plan} />
              )}
            </div>
            {!previewMode && (
              <PlanCardActions
                plan={plan}
                isCurrentPlan={isCurrentPlan}
                handleAddToCompare={handleAddToCompare}
                handleRemoveFromCompare={handleRemoveFromCompare}
                hideCompareButton={hideCompareButton}
              />
            )}
          </div>
          <div className="PlanCard__flex_right">
            <CollapsibleBox
              labelId="plan_card.view_more_information"
              containerClassname="PlanCard__more_info"
              titleClassname="PlanCard__more_info_trigger ds-c-button ds-c-button--ghost mct-hide-print"
              open={previewMode}
            >
              {isMedicareAdvantageType(plan.plan_type) ? (
                <>
                  <PlanBenefits plan={plan} />
                  <Copays plan={plan} />
                </>
              ) : (
                <Pharmacies
                  plan={plan}
                  drugCostInfo={drugCostInfo}
                  drugSelectionRoute={drugSelectionRoute}
                  hasCostsFromPlan={hasCostsFromPlan}
                  onGoToDrugSelection={onGoToDrugSelection}
                />
              )}
              <Drugs
                plan={plan}
                drugSelectionRoute={drugSelectionRoute}
                onGoToDrugSelection={onGoToDrugSelection}
              />
            </CollapsibleBox>
          </div>
        </div>
      </div>
      {!!compareList.length && (
        <SkipNav
          href={`#${compareAnchorTarget}`}
          onClick={(e): void => {
            e.preventDefault();
            document.getElementById(compareAnchorTarget)?.focus();
          }}
        >
          {t("planCard_skip_text")}
        </SkipNav>
      )}
    </Card>
  );
};

export default PlanCard;
