import { FC, PropsWithChildren } from "react";

/**
 * Maps a type so that no field can be `undefined` or `null`
 * Does not affect optional flag on any field
 */
export type NoUndefinedFields<T> = {
  [P in keyof T]: NonNullable<T[P]>;
};

export type FCWithChildren<T = Record<string, unknown>> = FC<
  PropsWithChildren<T>
>;

// Drug costs display helpers, Select Pharmacies page / map
export enum DrugCostMapDisplayLevel {
  Zero,
  UnderOneHundred,
  UnderTenThousand,
  OverTenThousand,
}

export interface DrugCostDisplayInfo {
  /** Use to display in the results section of the In-Network Pharmacy page */
  formatted: string;
  /** Use to display on map markers on the In-Network Pharmacy page */
  mapDisplay: string | undefined;
  /** Use to conditionally style markers based on yearly drug cost totals */
  drugCostMapDisplayLevel: DrugCostMapDisplayLevel | undefined;
}

export interface DrugCostDisplayInfoByPharmacy {
  [npi: string]: DrugCostDisplayInfo | undefined;
}

/**
 * Represents the format of a Mapbox address, either a truncated Mapbox full address
 * (no country), or from our own custom formatting helper
 * The format is `{street_address}, {city}, {state} {zipcode}`, where `{state}`
 * is a two-letter abbreviation
 *
 * Used to just narrow down the formatted type, where possible (can be aliased as
 * a simple `string` when needed)
 */
export type FormattedAddress = `${string}, ${string}, ${string} ${string}`;
