import URI from "urijs";
import {
  Envs,
  LocationWithOnlyPathnameRequired,
  LocationWithOptionalState,
  ManagedQueryParameters,
  QueryStringLanguage,
  UserLanguage,
} from "../@types";
import { RouteComponentProps, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { authenticatedMGovUrls, planComparePath } from "./CONSTANTS";
import { mGovUrls } from "./constants/medicareGovUrls";
import { logError } from "./errors";

export const subdomains = ["test", "dev", "imp", "www"] as const;
export type Subdomain = (typeof subdomains)[number];

export const getEnvironment = (): Envs => {
  const hostname = window.location.hostname;
  if (
    hostname.includes("localhost") ||
    hostname.includes("127.0.0.1") ||
    hostname.includes("0.0.0.0")
  ) {
    return Envs.local;
  }

  if (hostname.includes("test")) {
    return Envs.test;
  }

  if (hostname.includes("dev")) {
    return Envs.dev;
  }

  if (hostname.includes("imp")) {
    return Envs.imp;
  }

  return Envs.prod;
};

export const getLinkWithLanguage = (
  url: string,
  language: UserLanguage
): string => {
  const medicareSubdomain: UserLanguage.SPANISH | Extract<Subdomain, "www"> =
    language === UserLanguage.SPANISH ? UserLanguage.SPANISH : "www";
  return `https://${medicareSubdomain}.${url}`;
};

/**
 * @returns The MCT subdomain for each possible environment. Associates local
 * (e.g., `localhost`) with the TEST environment
 */
export const getSubdomainForEnvironment = (): Subdomain => {
  const environment = getEnvironment();
  const subdomainMap: Record<Envs, Subdomain> = {
    [Envs.local]: "test",
    [Envs.test]: "test",
    [Envs.dev]: "dev",
    [Envs.imp]: "imp",
    [Envs.prod]: "www",
  };
  return subdomainMap[environment];
};

export const getLinkWithLanguageAndEnvironment = (
  url: string,
  language: UserLanguage
): string => {
  const subdomain = getSubdomainForEnvironment();
  const link = new URI(`https://${subdomain}.${url}`);

  link.addQuery("lang", userToQueryStringLangMap[language]);

  return link.toString();
};

export const userToQueryStringLangMap: Record<
  UserLanguage,
  QueryStringLanguage
> = {
  [UserLanguage.ENGLISH]: QueryStringLanguage.ENGLISH,
  [UserLanguage.SPANISH]: QueryStringLanguage.SPANISH,
};

/**
 * Takes a React Router location object and converts it to a URIJS object, retaining
 * the `pathname`, `search`, and `hash` values. Does not preserve `location.state`.
 *
 * Only `pathname` is required in the location object, given that we may want to
 * manually construct objects and often that's the only value that we might care about
 */
export const convertLocationToUri = (
  location: LocationWithOnlyPathnameRequired
): ReturnType<typeof URI> =>
  new URI(location.pathname)
    .search(location?.search || "")
    .hash(location?.hash || "");

/**
 * Takes a URIJS object and converts it to a React Router location object, retaining
 * the `pathname`, `search`, and `hash` values
 */
export const convertUriToLocation = (
  uri: URI
): Omit<RouteComponentProps["location"], "state"> => ({
  pathname: uri.pathname(),
  search: uri.search(),
  hash: uri.hash(),
});

/**
 * Takes a React Router location object and converts it to a URIJS object, retaining
 * the `pathname`, `search`, and `hash` values, and also stripping managed query
 * parameters (`lang` and `year`) from the `search` value
 *
 * Only `pathname` is required in the location object, given that we may want to
 * manually construct objects and often that's the only value that we might care about
 */
export const convertLocationToUriStripManagedParams = (
  location: LocationWithOnlyPathnameRequired
): ReturnType<typeof URI> => {
  const uri = convertLocationToUri(location);
  uri.removeSearch(Object.values(ManagedQueryParameters));
  return uri;
};

/**
 * Takes current a location object, and returns a URL string that has managed query
 * params (`lang` and `year`) stripped out.
 *
 * Only `pathname` is required in the location object, given that we may want to
 * manually construct objects and often that's the only value that we might care about
 *
 * The returned string will contain `pathname`, `hash`, `search`, depending on what
 * was passed in
 */
export const convertLocationToStringStripManagedParams = (
  location: LocationWithOnlyPathnameRequired
): string => convertLocationToUriStripManagedParams(location).valueOf();

/**
 * Takes current a location object, and returns a modified object that has managed
 * query params (`lang` and `year`) stripped out.
 *
 * Only `pathname` is required in the location object, given that we may want to
 * manually construct objects and often that's the only value that we might care about
 *
 * The returned object or will contain `pathname`, `hash`, `search`, and
 * optionally `state` and/or `key`, depending on what was passed in
 */
export const stripManagedQueryParamsFromLocation = (
  location: LocationWithOnlyPathnameRequired
): LocationWithOptionalState => {
  const uri = convertLocationToUriStripManagedParams(location);
  return {
    ...location,
    search: uri.search(),
    hash: uri.hash(), // normalizes hash, prefixed with `#`
  };
};

/**
 * If a `returnTo` value is set in query (search) params, this hook will return
 * it. Useful to control or override where a "Back" button will take someone (as
 * opposed to the browser's back button)
 *
 * The `returnTo` URL string (`pathname` plus `search` and `hash`, if there are any)
 * will have managed query params (`lang` and `year`) stripped out from the `search`
 * portion of the string. This is to prevent locking down values that can be changed
 * by a user on any page (or a limited set of pages, in the case of `year`)
 *
 * Note: This would be cleaner if it used `history.push.state`, but that option
 * does not exist when using `HashRouter`
 */
export const useReturnTo = () => {
  const location = useLocation();
  const uri = convertLocationToUriStripManagedParams(location);
  const { returnTo } = uri.search(true);
  const hash = uri.hash();
  const [returnToVal, setReturnToVal] = useState<string | undefined>(undefined);
  useEffect(() => {
    if (returnTo) {
      setReturnToVal(`${returnTo}${hash}`);
    }
  }, [returnTo, hash]);
  return returnToVal;
};

/**
 * Takes current and link target location objects, and creates modified target location
 * in which the `search` string contains a `returnTo` URL that will bring the user
 * back to the place where they used the link
 *
 * Only `pathname` is required in either location object, given that we may want to
 * manually construct objects and often that's the only value that we might care about
 */
export const createTargetLocationWithReturnTo = ({
  currentLocation,
  targetLocation,
}: {
  currentLocation: LocationWithOnlyPathnameRequired;
  targetLocation: LocationWithOnlyPathnameRequired;
}): LocationWithOptionalState => {
  const returnToUri = convertLocationToUriStripManagedParams(currentLocation);
  const targetUriSearch = URI(targetLocation.search || "");
  targetUriSearch.addSearch("returnTo", returnToUri);
  const search = targetUriSearch.search();
  return {
    ...targetLocation,
    hash: targetLocation.hash || "",
    search,
  };
};

export const getIsInternalAnchorLinkHref = (href: string) => {
  const env = getEnvironment();
  const isLocal = env === Envs.local;
  // link is internal to MCT and contains two hashes - Aka, a named anchor
  const internalAnchorLinkRe = new RegExp(
    `.*${isLocal ? location.hostname : planComparePath}/#.*#`
  );
  return internalAnchorLinkRe.test(href);
};

/**
 * Gets the value to explicitly set as the `referrer` header in MCTAPI
 * requests. This value must end in a trailing slash for an API key to be generated.
 * Along with setting `referrerPolicy` to `strict-origin-when-cross-origin`, we
 * hope to minimize or prevent the `referer` (sic) header from being truncated (i.e.,
 * the pathname being removed), which causes 401 errors
 */
export const getReferrer = () => {
  let referrer = `${window.location.origin}${window.location.pathname}`;
  if (referrer.charAt(referrer.length - 1) !== "/") {
    referrer = `${referrer}/`;
  }
  return referrer;
};

export const linkToAccountHome = (language: UserLanguage): string => {
  return getLinkWithLanguageAndEnvironment(
    authenticatedMGovUrls.dashboard,
    language
  );
};

export const linkToMyPlans = (language: UserLanguage): string => {
  return getLinkWithLanguageAndEnvironment(
    authenticatedMGovUrls.dashboard,
    language
  );
};

export const linkToPartDLateEnrollmentPenalty = (
  language: UserLanguage
): string => {
  return getLinkWithLanguage(mGovUrls.partDLateEnrollmentPenalty, language);
};

export const linkToPartDCoPaymentCoInsurance = (
  language: UserLanguage
): string => {
  return getLinkWithLanguage(mGovUrls.partDCoPaymentCoInsurance, language);
};

export const linkToContacts = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.contacts, language);
};

export const linkToCostHelp = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.costHelp, language);
};

export const linkToCoverageWizard = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.coverageWizard, language);
};

export const linkToDrugCoveragePartD = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.drugCoveragePartD, language);
};

export const linkToGetHelpPayingCosts = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.getHelpPayingCosts, language);
};

export const linkToGetStartedWithMedicare = (
  language: UserLanguage
): string => {
  return getLinkWithLanguage(mGovUrls.getStartedWithMedicare, language);
};

export const linkToYourCoverageOptions = (language: UserLanguage): string => {
  return getLinkWithLanguage(
    `${mGovUrls.getStartedWithMedicare}/get-more-coverage/your-coverage-options`,
    language
  );
};

export const linkToHowDoMedicareAdvantagePlansWork = (
  language: UserLanguage
): string => {
  return getLinkWithLanguage(
    mGovUrls.howDoMedicareAdvantagePlansWork,
    language
  );
};

export const linkToHowOriginalMedicareWorks = (
  language: UserLanguage
): string => {
  return getLinkWithLanguage(mGovUrls.howOriginalMedicareWorks, language);
};

export const linkToJoiningAPlan = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.joiningAPlan, language);
};

export const linkToLowerPrescriptionCosts = (
  language: UserLanguage
): string => {
  return getLinkWithLanguage(mGovUrls.lowerPrescriptionCosts, language);
};

export const linkToPaceHome = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.paceHome, language);
};

export const linkToPaceInfo = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.paceInfo, language);
};

export const linkToMedicareAndYou = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.medicareAndYou, language);
};

export const linkToMedicareHome = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.medicareHome, language);
};

export const linkToPrescriptionCostHelp = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.prescriptionCostHelp, language);
};

export const linkToSpecialEnrollmentPeriodInfo = (
  language: UserLanguage
): string => {
  return getLinkWithLanguage(mGovUrls.specialEnrollmentPeriodInfo, language);
};

export const linkToMedigapBasics = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.medigapBasics, language);
};

export const linkToMedigapGuaranteedIssueRights = (
  language: UserLanguage
): string => {
  return getLinkWithLanguage(mGovUrls.medigapGuaranteedIssueRights, language);
};

export const linkToMedigapInfo = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.medigapInfo, language);
};

export const linkToMedigapCostsOfPolicies = (
  language: UserLanguage
): string => {
  return getLinkWithLanguage(mGovUrls.medigapCostsOfPolicies, language);
};

export const linkToMedigapStateInsuranceDepartments = (
  language: UserLanguage
): string => {
  return getLinkWithLanguage(
    mGovUrls.medigapStateInsuranceDepartments,
    language
  );
};

export const linkToMedigapWaiverStateInfo = (
  language: UserLanguage,
  state: string
): string => {
  return getLinkWithLanguage(mGovUrls.medigapWaiverStateInfo + state, language);
};

export const linkToTypesOfHealthPlans = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.typesOfHealthPlans, language);
};

export const linkToWhatMedicareCovers = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.whatMedicareCovers, language);
};

export const linkToWhenMedicareStarts = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.whenMedicareStarts, language);
};

export const linkToContactMedicare = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.talkToSomeone, language);
};

export const linkToEndStageRenalDisease = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.endStageRenalDisease, language);
};

export const linkToAvoidPenalities = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.avoidPenalties, language);
};

export const linkToSNP = (language: UserLanguage): string => {
  return getLinkWithLanguage(mGovUrls.snp, language);
};

export const getLinkToMedicare = ({
  language,
  mgovUrlKey,
}: {
  language: UserLanguage;
  mgovUrlKey: keyof typeof mGovUrls;
}) => getLinkWithLanguage(mGovUrls[mgovUrlKey], language);

export const getPageNameFromPathName = (pathname: string) =>
  pathname.replace("/", "").split("/", 1).join("").replace(/-/g, " ");

/**
 * Takes in a URL and an MCT subdomain to insert at the beginning of the hostname
 * for the returned URL.
 *
 * If the URL has a non-standard subdomain -- e.g., a language or a language plus
 * a Medicare-specific environment, this helper will strip those and replace them
 * with the subdomain passed in.
 *
 * If the passed in URL is invalid, it will return the default MCT anonymous landing
 * page for the provided subdomain
 *
 * NOTE: This helpers associates local (e.g., `localhost`) with the TEST environment.
 * If you want local URLs to stay pointing at your local, simply skip running this
 * helper in that environment. (local doesn't have a subdomain unless aliased by your server)
 *
 * @returns a URL with the passed in subdomain
 */
export const setSubdomain = ({
  subdomain,
  url,
}: {
  subdomain: Subdomain;
  url: string;
}) => {
  let parsedUrl = new URL(
    `https://${subdomain}.${mGovUrls.medicareHome}/${planComparePath}`
  );
  try {
    parsedUrl = new URL(url);
  } catch (e) {
    logError("Invalid URL passed to setSubdomain helper", e as Error);
    return parsedUrl.toString();
  }
  let hostnameArray = parsedUrl.hostname.split(".");
  // Possible that there is no subdomain set (hostnameArray is only 2 values)
  if (hostnameArray.length > 2) {
    hostnameArray = hostnameArray.slice(1);
  }
  const possibleSubdomains: Subdomain[] = ["test", "dev", "imp", "www"];
  // A URL may start with a language subdomain and a secondary environment subdomain,
  // in which case all that needs to be done is for the language portion to be stripped
  const newhostnameArray =
    hostnameArray[0] === subdomain
      ? hostnameArray
      : // if the subdomain passed in is different, swap it with the new one
      hostnameArray.length === 3 &&
        possibleSubdomains.includes(hostnameArray[0] as Subdomain)
      ? [subdomain, ...hostnameArray.slice(1)]
      : // Otherwise, add the environment subdomain to the rest of the hostname
        [subdomain, ...hostnameArray];
  parsedUrl.hostname = newhostnameArray.join(".");
  return parsedUrl.toString();
};
