import { LDFlagSet } from "launchdarkly-js-client-sdk";
import { isValidDate, isValidBirthdate } from "./dateHelpers";
import states from "./states";
import {
  EnrollPhoneNumber,
  PlanSNPType,
  PlanType,
  PlanCategoryType,
  MCTDate,
  BasePlan,
  EnrollFormData,
  EnrollDataForAPI,
  GetBenefitsFrom,
  Sex,
  Plan,
  AppState,
} from "../@types";
import { isEmptySSN, isValidSsn, SSN } from "../components/SSNInput";
import { isValidEmail } from "./objectUtilities";
import { isMapdPlanType, isPdPlanType } from "./planTypes";

export const emptyEnrollData: EnrollFormData = {
  additionalDrugCoverage: {
    name: "",
    id: "",
    group: "",
  },
  address1: "",
  address2: "",
  authorizedRepresentative: {
    name: "",
    relationship: "",
    addressLine1: "",
    city: "",
    state: "",
    zip: "",
    phone: { areaCode: "", exchangeCode: "", lineNumber: "" },
  },
  city: "",
  county: undefined,
  dob: { day: "", month: "", year: "" },
  ethnicities: [],
  email: "",
  firstName: "",
  hasSepOtherReason: false,
  lastName: "",
  mailingAddress1: "",
  mailingAddress2: "",
  mailingCity: "",
  mailingState: "",
  mailingZip: "",
  medicareNumber: "",
  middleInitial: "",
  premiumWithhold: GetBenefitsFrom.WITHHOLD_NONE,
  primaryCareName: "",
  primaryPhone: { areaCode: "", exchangeCode: "", lineNumber: "" },
  races: [],
  sepOtherReason: "",
  sepReasons: [],
  sex: Sex.NONE,
  ssn: { areaNumber: "", groupNumber: "", serialNumber: "" },
  state: "",
  zip: "",
};

const getStateAbbrFromFipsCode = (stateCode: string): string => {
  const state = states.find(s => s.fipsCode === stateCode);
  return state ? state.abbreviation : "";
};

export const removeExtraWhiteSpace = (str: string): string => {
  return str.replace(/\s+/g, " ").trim();
};

const formatDate = (date: MCTDate): string => {
  return date.year && date.month && date.day
    ? `${date.year}-${date.month}-${date.day}`
    : "";
};

export const formatPhone = (
  phone: EnrollPhoneNumber,
  withDashes = true
): string => {
  const { areaCode, exchangeCode, lineNumber } = phone;
  const delimiter = withDashes ? "-" : "";

  return areaCode && exchangeCode && lineNumber
    ? [phone.areaCode, phone.exchangeCode, phone.lineNumber].join(delimiter)
    : "";
};

export const formatSSN = (ssn: SSN): string => {
  return ssn.areaNumber && ssn.groupNumber && ssn.serialNumber
    ? `${ssn.areaNumber}${ssn.groupNumber}${ssn.serialNumber}`
    : "";
};

export const isCostPlanWithDrugs = (plan: BasePlan): boolean =>
  plan.plan_type !== PlanType.MA &&
  plan.category === PlanCategoryType.COST_1876;

export const isCostPlanWithoutDrugs = (plan: BasePlan): boolean =>
  plan.plan_type === PlanType.MA &&
  plan.category === PlanCategoryType.COST_1876;

export const isPffsMa = (plan: BasePlan): boolean =>
  plan.plan_type === PlanType.MA && plan.category === PlanCategoryType.PFFS;

export const isPffsWithDrugs = (plan: BasePlan): boolean =>
  plan.plan_type !== PlanType.MA && plan.category === PlanCategoryType.PFFS;

export const shouldShowRaceEthnicitySection = (
  plan: BasePlan,
  tmpFeEnableEnrollmentRaceEthnicity: boolean
): boolean =>
  tmpFeEnableEnrollmentRaceEthnicity &&
  plan.category != PlanCategoryType.COST_1876;

export const shouldShowDrugCoverageQuestion = (plan: BasePlan): boolean => {
  const isMAPD = isMapdPlanType(plan.plan_type);
  const isPDP = isPdPlanType(plan.plan_type);
  const isCP_PD = isCostPlanWithDrugs(plan);
  const isPFFS_PD = isPffsWithDrugs(plan);
  const isSNP_DE = plan.snp_type === PlanSNPType.SNP_TYPE_DUAL_ELIGIBLE;

  return isMAPD || isPDP || isSNP_DE || isPFFS_PD || isCP_PD;
};

export const serializePayload = ({
  enrollData,
  selectedPlan,
  isM3pEligible,
  state,
}: {
  enrollData: EnrollFormData;
  selectedPlan: Plan;
  isM3pEligible: boolean;
  state: AppState;
}): EnrollDataForAPI => {
  const sepReasonCodes = enrollData.sepReasons.map(r => {
    const { code, date } = r;

    return {
      code: `SEP_REASON_CODE_${code}`,
      date: date ? formatDate(date) : "",
    };
  });

  return {
    plan_year: selectedPlan ? selectedPlan.contract_year : "",
    contract_id: selectedPlan ? selectedPlan.contract_id : "",
    plan_id: selectedPlan ? selectedPlan.plan_id : "",
    segment_id: selectedPlan ? selectedPlan.segment_id : "",
    applicant_info: {
      mbi: enrollData.medicareNumber,
      ssn: formatSSN(enrollData.ssn),
      first_name: enrollData.firstName,
      middle_initial: enrollData.middleInitial,
      last_name: enrollData.lastName,
      birthdate: formatDate(enrollData.dob),
      gender: enrollData.sex,
      home_address: {
        address_1: enrollData.address1,
        address_2: enrollData.address2,
        city: enrollData.city,
        state: getStateAbbrFromFipsCode(enrollData.state),
        zipcode: enrollData.zip,
        county: enrollData.county ? enrollData.county.name : "",
      },
      mail_address: {
        address_1: enrollData.mailingAddress1,
        address_2: enrollData.mailingAddress2,
        city: enrollData.mailingCity,
        state: getStateAbbrFromFipsCode(enrollData.mailingState),
        zipcode: enrollData.mailingZip,
      },
      phone: formatPhone(enrollData.primaryPhone, false),
      email_address: enrollData.email,
      language: enrollData.language || null,
      ethnicities: enrollData.ethnicities,
      races: enrollData.races,
    },
    other_coverage: enrollData.hasAdditionalDrugCoverage
      ? {
          name: enrollData.additionalDrugCoverage.name,
          id: enrollData.additionalDrugCoverage.id,
          group: enrollData.additionalDrugCoverage.group,
        }
      : null,
    authorized_rep: enrollData.isEnrollee
      ? null
      : {
          name: enrollData.authorizedRepresentative.name,
          address: {
            address_1: enrollData.authorizedRepresentative.addressLine1,
            address_2: "",
            city: enrollData.authorizedRepresentative.city,
            state: getStateAbbrFromFipsCode(
              enrollData.authorizedRepresentative.state
            ),
            zipcode: enrollData.authorizedRepresentative.zip,
          },
          phone: formatPhone(enrollData.authorizedRepresentative.phone, false),
          relation: enrollData.authorizedRepresentative.relationship,
        },
    accessibility_format: enrollData.accessibilityFormat || null,
    email_opt_in: !!enrollData.emailOptIn,
    work_status:
      enrollData.beneWorks === undefined ? null : enrollData.beneWorks,
    spouse_work_status:
      enrollData.spouseWorks === undefined ? null : enrollData.spouseWorks,
    primary_care_physician: enrollData.primaryCareName,
    // @TODO - Look at refactoring to use `useIsCsrSession` boolean `isCsr` or
    // `getIsCsrSession` helper
    agent_id: state.csr ? state.csr.id : null,
    lis_level: state.lis,
    sep_reason_codes: sepReasonCodes,
    sep_cms_reason_code: enrollData.sepOtherReason,
    premium_direct_pay:
      enrollData.premiumDirectPay === undefined
        ? true
        : enrollData.premiumDirectPay,
    premium_withhold: enrollData.premiumWithhold,
    is_m3p_eligible: isM3pEligible,
  };
};

export type EnrollmentPage =
  | "Sep"
  | "MedicareInfo"
  | "PersonalInfo"
  | "Address"
  | "GeneralQuestions"
  | "Paying"
  | "Agreement"
  | "TellUsMore"
  | "Review"
  | "Enrolled";

export const defaultStepPages: EnrollmentPage[] = [
  "MedicareInfo",
  "PersonalInfo",
  "Address",
  "GeneralQuestions",
  "Paying",
  "Agreement",
  "Review",
];

export const defaultOrderedPages: EnrollmentPage[] = [
  ...defaultStepPages,
  "Enrolled",
];

export const defaultIncludeSepOrderedPages: EnrollmentPage[] = [
  "Sep",
  ...defaultOrderedPages,
];

export interface Validity {
  isValid: boolean;
  medicareNumberValidity?: boolean;
  ssnValidity?: boolean;
  partAStartValidity?: boolean;
  partBStartValidity?: boolean;
  understoodCoverageLossWarningValidity?: boolean;
  sexValidity?: boolean;
  firstNameValidity?: boolean;
  middleInitialValidity?: boolean;
  lastNameValidity?: boolean;
  titleValidity?: boolean;
  dobValidity?: boolean;
  phoneValidity?: boolean;
  emailValidity?: boolean;
  addressValidity?: boolean;
  cityValidity?: boolean;
  stateValidity?: boolean;
  zipValidity?: boolean;
  countyValidity?: boolean;
  mailingAddressValidity?: boolean;
  mailingCityValidity?: boolean;
  mailingStateValidity?: boolean;
  mailingZipValidity?: boolean;
  renalDiseaseQuestionValidity?: boolean;
  hasAdditionalDrugCoverageValidity?: boolean;
  additionalDrugCoverageNameValidity?: boolean;
  additionalDrugCoverageIdValidity?: boolean;
  additionalDrugCoverageGroupValidity?: boolean;
  longTermCareNameValidity?: boolean;
  longTermCareAddressLine1Validity?: boolean;
  longTermCareCityValidity?: boolean;
  longTermCareStateValidity?: boolean;
  longTermCareZipValidity?: boolean;
  longTermCarePhoneValidity?: boolean;
  enrolledInMedicaidQuestionValidity?: boolean;
  agreeToContractValidity?: boolean;
  agreeToInformationReleaseValidity?: boolean;
  isEnrolleeValidity?: boolean;
  authorizedRepresentativeNameValidity?: boolean;
  authorizedRepresentativeRelationshipValidity?: boolean;
  authorizedRepresentativeAddressLine1Validity?: boolean;
  authorizedRepresentativeCityValidity?: boolean;
  authorizedRepresentativeStateValidity?: boolean;
  authorizedRepresentativeZipValidity?: boolean;
  authorizedRepresentativePhoneValidity?: boolean;
  readAndUnderstoodContentsValidity?: boolean;
  sepOtherReasonValidity?: boolean;
  sepReasonsValidity?: boolean;
  ethnicitiesValidity?: boolean;
  racesValidity?: boolean;
}

export const mbiRegex = /^\d[A-Z]\w\d[A-Z]\w\d[A-Z]{2}\d{2}$/g;

export const isValidMedicareNumber = (
  medicareNumber: string | undefined
): boolean => {
  if (!medicareNumber) return false;

  const isMbi = !!medicareNumber.match(mbiRegex);

  return isMbi;
};

const isValidZip = (zip: string): boolean => {
  return !!zip.match(/^\d{5}$/);
};

export const getMedicareInfoValidity = (
  enrollData: EnrollFormData,
  plan: BasePlan
): Validity => {
  const medicareNumberValidity = isValidMedicareNumber(
    enrollData.medicareNumber
  );
  const ssnValidity =
    plan.snp_type === PlanSNPType.SNP_TYPE_DUAL_ELIGIBLE
      ? isEmptySSN(enrollData.ssn) || isValidSsn(enrollData.ssn)
      : true;
  const understoodCoverageLossWarningValidity =
    !!enrollData.understoodCoverageLossWarning;

  return {
    isValid:
      medicareNumberValidity &&
      ssnValidity &&
      understoodCoverageLossWarningValidity,
    medicareNumberValidity,
    ssnValidity,
    understoodCoverageLossWarningValidity,
  };
};

export const getPersonalInfoValidity = (
  enrollData: EnrollFormData,
  plan: BasePlan,
  flags?: LDFlagSet
): Validity => {
  const sexValidity = !!enrollData.sex.trim();
  const firstNameValidity = !!enrollData.firstName.trim();
  const middleInitialValidity =
    enrollData.middleInitial === "" ||
    !!enrollData.middleInitial.trim().match(/^[a-z|A-Z]$/);
  const lastNameValidity = !!enrollData.lastName.trim();
  const dobValidity = isValidBirthdate(enrollData.dob);
  const phoneValidity =
    enrollData.primaryPhone.areaCode.length === 3 &&
    enrollData.primaryPhone.exchangeCode.length === 3 &&
    enrollData.primaryPhone.lineNumber.length === 4;

  let ethnicitiesValidity = true;
  let racesValidity = true;

  if (flags) {
    const { tmpFeEnableEnrollmentRaceEthnicity } = flags;
    const showRaceEthnicitySection = shouldShowRaceEthnicitySection(
      plan,
      Boolean(tmpFeEnableEnrollmentRaceEthnicity)
    );
    if (showRaceEthnicitySection) {
      if ("ethnicities" in enrollData) {
        ethnicitiesValidity = enrollData.ethnicities.length > 0;
      }
      if ("races" in enrollData) {
        racesValidity = enrollData.races.length > 0;
      }
    }
  }

  return {
    isValid:
      sexValidity &&
      firstNameValidity &&
      middleInitialValidity &&
      lastNameValidity &&
      dobValidity &&
      phoneValidity &&
      ethnicitiesValidity &&
      racesValidity,
    sexValidity,
    firstNameValidity,
    middleInitialValidity,
    lastNameValidity,
    dobValidity,
    phoneValidity,
    ethnicitiesValidity,
    racesValidity,
  };
};

const getAddressValidity = (enrollData: EnrollFormData): Validity => {
  const addressValidity = !!enrollData.address1.trim();
  const cityValidity = !!enrollData.city.trim();
  const stateValidity = !!enrollData.state;
  const zipValidity = !!enrollData.zip.trim() && isValidZip(enrollData.zip);
  const countyValidity = !!enrollData.county;

  let mailingAddressValidity = true;
  let mailingCityValidity = true;
  let mailingStateValidity = true;
  let mailingZipValidity = true;
  if (enrollData.differentMailingAddress) {
    mailingAddressValidity = !!enrollData.mailingAddress1.trim();
    mailingCityValidity = !!enrollData.mailingCity.trim();
    mailingStateValidity = !!enrollData.mailingState;
    mailingZipValidity =
      !!enrollData.mailingZip.trim() && isValidZip(enrollData.mailingZip);
  }

  return {
    isValid:
      addressValidity &&
      cityValidity &&
      stateValidity &&
      zipValidity &&
      countyValidity &&
      mailingAddressValidity &&
      mailingCityValidity &&
      mailingStateValidity &&
      mailingZipValidity,
    addressValidity,
    cityValidity,
    stateValidity,
    zipValidity,
    countyValidity,
    mailingAddressValidity,
    mailingCityValidity,
    mailingStateValidity,
    mailingZipValidity,
  };
};

const getGeneralQuestionsValidity = (
  enrollData: EnrollFormData,
  plan: BasePlan
): Validity => {
  const hasAdditionalDrugCoverageValidity = shouldShowDrugCoverageQuestion(plan)
    ? enrollData.hasAdditionalDrugCoverage !== undefined
    : true;
  const additionalDrugCoverageNameValidity =
    enrollData.hasAdditionalDrugCoverage
      ? !!enrollData.additionalDrugCoverage.name
      : true;
  const additionalDrugCoverageIdValidity = enrollData.hasAdditionalDrugCoverage
    ? !!enrollData.additionalDrugCoverage.id
    : true;
  const additionalDrugCoverageGroupValidity =
    enrollData.hasAdditionalDrugCoverage
      ? !!enrollData.additionalDrugCoverage.group
      : true;
  const emailValidity = enrollData.emailOptIn
    ? isValidEmail(enrollData.email)
    : true;

  return {
    isValid:
      hasAdditionalDrugCoverageValidity &&
      additionalDrugCoverageNameValidity &&
      additionalDrugCoverageIdValidity &&
      additionalDrugCoverageGroupValidity &&
      emailValidity,
    additionalDrugCoverageNameValidity,
    additionalDrugCoverageIdValidity,
    additionalDrugCoverageGroupValidity,
    emailValidity,
    hasAdditionalDrugCoverageValidity,
  };
};

const getAgreementValidity = (enrollData: EnrollFormData): Validity => {
  const agreeToContractValidity = !!enrollData.agreeToContract;
  const isEnrolleeValidity = enrollData.isEnrollee !== undefined;
  const authorizedRepresentativeNameValidity = !enrollData.isEnrollee
    ? !!enrollData.authorizedRepresentative.name
    : true;
  const authorizedRepresentativeRelationshipValidity = !enrollData.isEnrollee
    ? !!enrollData.authorizedRepresentative.relationship
    : true;
  const authorizedRepresentativeAddressLine1Validity = !enrollData.isEnrollee
    ? !!enrollData.authorizedRepresentative.addressLine1
    : true;
  const authorizedRepresentativeCityValidity = !enrollData.isEnrollee
    ? !!enrollData.authorizedRepresentative.city
    : true;
  const authorizedRepresentativeStateValidity = !enrollData.isEnrollee
    ? !!enrollData.authorizedRepresentative.state
    : true;
  const authorizedRepresentativeZipValidity = !enrollData.isEnrollee
    ? !!enrollData.authorizedRepresentative.zip &&
      isValidZip(enrollData.authorizedRepresentative.zip)
    : true;
  const authorizedRepresentativePhoneValidity = !enrollData.isEnrollee
    ? !!enrollData.authorizedRepresentative.phone &&
      enrollData.authorizedRepresentative.phone.areaCode.length === 3 &&
      enrollData.authorizedRepresentative.phone.exchangeCode.length === 3 &&
      enrollData.authorizedRepresentative.phone.lineNumber.length === 4
    : true;

  return {
    isValid:
      agreeToContractValidity &&
      isEnrolleeValidity &&
      authorizedRepresentativeNameValidity &&
      authorizedRepresentativeRelationshipValidity &&
      authorizedRepresentativeAddressLine1Validity &&
      authorizedRepresentativeCityValidity &&
      authorizedRepresentativeStateValidity &&
      authorizedRepresentativeZipValidity &&
      authorizedRepresentativePhoneValidity,
    agreeToContractValidity,
    isEnrolleeValidity,
    authorizedRepresentativeNameValidity,
    authorizedRepresentativeRelationshipValidity,
    authorizedRepresentativeAddressLine1Validity,
    authorizedRepresentativeCityValidity,
    authorizedRepresentativeStateValidity,
    authorizedRepresentativeZipValidity,
    authorizedRepresentativePhoneValidity,
  };
};

const getReviewValidity = (enrollData: EnrollFormData): Validity => {
  const readAndUnderstoodContentsValidity =
    enrollData.readAndUnderstoodContents;
  return {
    isValid: !!enrollData.readAndUnderstoodContents,
    readAndUnderstoodContentsValidity,
  };
};

const getSepValidity = (enrollData: EnrollFormData): Validity => {
  const { sepReasons, hasSepOtherReason, sepOtherReason } = enrollData;

  let datesValid = true;
  let otherReasonValid = true;
  let reasonsValid = true;

  if (!sepReasons.length && !sepOtherReason) {
    reasonsValid = false;
  }

  if (hasSepOtherReason && !sepOtherReason) {
    otherReasonValid = false;
  }

  sepReasons.forEach(reason => {
    if (reason.requiresDate && (!reason.date || !isValidDate(reason.date))) {
      datesValid = false;
    }
  });

  return {
    isValid: datesValid && otherReasonValid && reasonsValid,
    sepReasonsValidity: reasonsValid,
    sepOtherReasonValidity: otherReasonValid,
  };
};

export const getValidationObject = (
  enrollData: EnrollFormData,
  plan: BasePlan,
  page: EnrollmentPage,
  flags?: LDFlagSet
): Validity => {
  switch (page) {
    case "Sep":
      return getSepValidity(enrollData);
    case "MedicareInfo":
      return getMedicareInfoValidity(enrollData, plan);
    case "PersonalInfo":
      return getPersonalInfoValidity(enrollData, plan, flags);
    case "Address":
      return getAddressValidity(enrollData);
    case "GeneralQuestions":
      return getGeneralQuestionsValidity(enrollData, plan);
    case "Agreement":
      return getAgreementValidity(enrollData);
    case "Review":
      return getReviewValidity(enrollData);
    default:
      return {
        isValid: true,
      };
  }
};

export const getEnrollmentPages = (
  includeSepPage: boolean
): { orderedPages: EnrollmentPage[]; stepPages: EnrollmentPage[] } => {
  // TODO: Rename these variables to be more intuitive.
  const orderedPages = includeSepPage
    ? defaultIncludeSepOrderedPages
    : defaultOrderedPages;

  return { orderedPages, stepPages: defaultStepPages };
};
