import React, { HTMLProps, FC } from "react";
import { getLinkWithLanguage } from "../helpers/urlHelpers";
import { useAppContext } from "../helpers/context-hooks/useAppContext";
import { mGovUrls } from "../helpers/constants/medicareGovUrls";

type MedicareGovAnchorProps = Omit<
  HTMLProps<HTMLAnchorElement>,
  "href" | "target" | "rel"
> & {
  urlKey: keyof typeof mGovUrls;
  sameTab?: boolean;
};

/** anchor tag which can tag keys from `mGovUrls` and properly route them
 * @prop sameTab  - set to `true` to open in the same tab, instead of a new tab.
 * @prop urlKey - a key for a path defined in `mGovUrls`
 * @see /src/helpers/CONSTANTS.ts
 * @example <MedicareGovAnchor urlKey="medigap">
        {t("ntm.find_plans_now.help_drawer.learn_more_medigap")}
      </MedicareGovAnchor>   
 */
export const MedicareGovAnchor: FC<MedicareGovAnchorProps> = ({
  urlKey,
  sameTab,
  children,
  ...props
}) => {
  const {
    state: { language },
  } = useAppContext();

  const target = sameTab ? undefined : "_blank";
  const rel = sameTab ? undefined : "noopener noreferrer";

  return (
    <a
      href={getLinkWithLanguage(mGovUrls[urlKey], language)}
      target={target}
      rel={rel}
      {...props}
    >
      {children}
    </a>
  );
};
