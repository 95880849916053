/* istanbul ignore file */
import { useState, useEffect, useRef, useMemo, MutableRefObject } from "react";
import { hasMailOrderPharmacy } from "../app/store/appStoreHelpers";
import {
  Plan,
  SearchResultPlan,
  DrugCostInfo,
  LowIncomeSubsidyStatus,
  PrescriptionFrequency,
  Pharmacy,
  DrugCostPrescriptionInfo,
  PrescriptionDrug,
  DrugInfo,
  IdParts,
  DrugCostsResponse,
  NoUndefinedFields,
  UseTranslateTypeStrict,
  DrugCosts,
  ActionType,
  DrugCostDisplayInfoByPharmacy,
  DrugCostMapDisplayLevel,
} from "../@types";
import { getDrugCosts, getOtherDrugInfo } from "../api";
import { useTranslate, useTypedTranslate } from "./intlHooks";
import { getFullPlanId, makePlanUrlPartForApi } from "./objectUtilities";
import { isSearchResultPlan } from "../@types/guards";
import { ApiError, logError } from "./errors";
import { useAppContext } from "./context-hooks/useAppContext";
import { useCurrentPlanYear } from "./yearFlagHelpers";
import { $$, $$Whole } from "./currencyUtilities";
import { normalizeNpi } from "./pharmacyHelpers";
import { shouldSuppressDrugPricing } from "./planHelpers";
import { mailOrderNpi } from "./CONSTANTS";

export const getDefaultDrugCostInfo = ({
  plan,
  t,
}: {
  plan: Plan | SearchResultPlan;
  t: UseTranslateTypeStrict;
}): DrugCostInfo => ({
  costs: [],
  lowest_mail_total: t("plan_card.calculating"),
  lowest_retail_total: t("plan_card.calculating"),
  plan: getFullPlanId(plan),
  excluded_drugs: [],
  restrictions: [],
});

export const getPrescriptionInfoForDrugCosts = (
  prescriptions: PrescriptionDrug[]
): DrugCostPrescriptionInfo[] =>
  prescriptions.map(p => ({
    ndc: p.ndc,
    frequency: p.frequency as PrescriptionFrequency,
    quantity: String(p.quantity), // FIXME: Need to update this to use the PDE quantity data if isHidden is true
  }));

export const getPharmacyNpis = (pharmacies: Pharmacy[]) =>
  pharmacies.map(p => p.npi);

export const getLisForDrugCosts = ({
  futureLis,
  lis,
  mctCurrentPlanYear,
  plan,
}: {
  futureLis: LowIncomeSubsidyStatus | undefined;
  lis: LowIncomeSubsidyStatus | undefined;
  mctCurrentPlanYear: number;
  plan: Plan | SearchResultPlan;
}): LowIncomeSubsidyStatus => {
  let lisToUse = lis ? lis : LowIncomeSubsidyStatus.LIS_NO_HELP;
  // anon users do not use futureLis
  if (+plan.contract_year !== mctCurrentPlanYear && futureLis) {
    lisToUse = futureLis;
  }
  return lisToUse;
};

/**
 * Optional `fullYearPricing` and `retailOnly` both default to `false`
 * @TODO - When ultimately refactored to leverage React Query, can we enable
 * fetching costs for multiple plans by using the `planId[]` of the endpoint?
 * Currently we only ever use a single plan and pass it to MCTAPI as `[planId]`
 * @deprecated use `useDrugCosts()` instead.
 */
export const fetchCosts = async ({
  fullYearPricing = false,
  futureLis,
  lis,
  mctCurrentPlanYear,
  prescriptions,
  pharmacies,
  plan,
  retailOnly = false,
}: {
  fullYearPricing?: boolean;
  futureLis: LowIncomeSubsidyStatus | undefined;
  lis: LowIncomeSubsidyStatus | undefined;
  mctCurrentPlanYear: number;
  prescriptions: PrescriptionDrug[];
  pharmacies: Pharmacy[];
  plan: Plan | SearchResultPlan;
  retailOnly?: boolean;
}): Promise<FetchCostsReturnValues> => {
  const prescriptionInfoForDrugCosts =
    getPrescriptionInfoForDrugCosts(prescriptions);
  const npis = getPharmacyNpis(pharmacies);
  const planId = getFullPlanId(plan);

  const lisToUse = getLisForDrugCosts({
    futureLis,
    lis,
    mctCurrentPlanYear,
    plan,
  });

  let returnValues: FetchCostsReturnValues = {
    drugCostInfo: undefined,
    full_year: false,
  };

  try {
    const response = await getDrugCosts({
      npis,
      prescriptions: prescriptionInfoForDrugCosts,
      planIds: [planId],
      lis: lisToUse,
      fullYearPricing,
      retailOnly,
    });

    if (response) {
      const { plans, full_year } = response;
      const firstPlanMeetsCap =
        typeof plans[0].cumulative_meets_annual_cap === "boolean";
      returnValues = {
        drugCostInfo: {
          ...plans[0],
          // @TODO - Revise when MCTAPI is updated and `cumulative_meets_annual_cap`
          // is part of the response
          // @see https://github.cms.gov/CMS-MCT/coverage-tools-api/pull/2638
          cumulative_meets_annual_cap: firstPlanMeetsCap
            ? plans[0].cumulative_meets_annual_cap
            : false,
        },
        full_year: full_year,
      };
    }
  } catch (err) {
    logError(
      "Could not get drug costs (costsHelpers/fetchCosts)",
      err as ApiError
    );
  }

  return returnValues;
};

/**
 * Pass `pharmacies` as an empty array if you do not want `useCosts` to fall
 * back to using `state.pharmacies`
 *
 * If you pass a `ref`, costs should be fetched when that ref is detected in the
 * viewport by an `IntersectionObserver`
 *
 * The optional `fullYearPricing` param, according to the API: "requesting the
 * calculation for the whole year." This is used by YoY Plan Compare
 */
export interface UseCostsParams {
  ref?: MutableRefObject<HTMLElement | null>;
  pharmacies?: Pharmacy[];
  fullYearPricing?: boolean;
  plan: Plan | SearchResultPlan;
  retailOnly?: boolean;
}

export type FetchCostsReturnValues = Omit<
  DrugCostsResponse,
  "request_id" | "plans"
> & { drugCostInfo: DrugCostInfo | undefined };

export type UseCostsReturnValues = NoUndefinedFields<FetchCostsReturnValues>;

/**
 * Initializes a default, then fetches and updates DrugCostInfo for a particular plan
 * and a specific set of pharmacies.
 * If a `ref` to an element is passed, that element is observed using `IntersectionObserver`
 * and costs fetched only when the element comes into view.
 * @deprecated use `useDrugCosts()` instead
 */
export const useCosts = ({
  fullYearPricing = false,
  pharmacies: pharmaciesParam,
  plan,
  ref: observedElementRef,
  retailOnly = false,
}: UseCostsParams): UseCostsReturnValues => {
  const t = useTranslate();
  const {
    state,
    state: { futureLis, lis, prescriptions },
  } = useAppContext();
  const mctCurrentPlanYear = useCurrentPlanYear();
  const initialCostReturnValues: UseCostsReturnValues = {
    drugCostInfo: getDefaultDrugCostInfo({ plan, t }),
    full_year: false,
  };
  const [fetchCostsReturnValues, setFetchCostsReturnValues] =
    useState<UseCostsReturnValues>(initialCostReturnValues);
  const pharmacies = pharmaciesParam || state.pharmacies;
  const hasPassedInPharmacies = !!pharmaciesParam && pharmaciesParam.length > 0;
  const useAppStatePharmacies = !pharmaciesParam;
  const shouldFetch = hasPassedInPharmacies || useAppStatePharmacies;
  const fetchingRef = useRef(false);
  const observerRef = useRef<IntersectionObserver | null>(null);

  const updateReturnValues = (
    fetchCostsReturnValues: FetchCostsReturnValues
  ) => {
    const { drugCostInfo, full_year } = fetchCostsReturnValues;
    // Only update if there's something to update for `drugCostInfo` (which, by
    // extension, means the other values should be defined)
    // By default, we return default cost info for the plan
    if (drugCostInfo) {
      setFetchCostsReturnValues({
        drugCostInfo,
        full_year,
      });
    }
    fetchingRef.current = false;
  };

  const fetchCostsParams = useMemo(
    () => ({
      fullYearPricing,
      futureLis,
      lis,
      mctCurrentPlanYear,
      pharmacies,
      plan,
      prescriptions,
      retailOnly,
    }),
    [
      fullYearPricing,
      futureLis,
      lis,
      mctCurrentPlanYear,
      pharmacies,
      plan,
      prescriptions,
      retailOnly,
    ]
  );

  useEffect(() => {
    if (
      observerRef.current ||
      !observedElementRef ||
      !observedElementRef.current
    ) {
      return;
    }
    observerRef.current = new IntersectionObserver(
      ([entry]: IntersectionObserverEntry[]): void => {
        if (entry.isIntersecting && !fetchingRef.current && shouldFetch) {
          fetchingRef.current = true;
          fetchCosts(fetchCostsParams).then(fetchCostsReturnValues => {
            updateReturnValues(fetchCostsReturnValues);
          });
        }
      }
    );
  }, [fetchCostsParams, observedElementRef, shouldFetch]);

  useEffect(() => {
    if (
      !observedElementRef ||
      !observedElementRef.current ||
      !observerRef.current
    ) {
      return;
    }
    observerRef.current.observe(observedElementRef.current);
    return (): void => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [observedElementRef]);

  useEffect(() => {
    // `observedElementRef` is handled by another effect
    if (
      observedElementRef ||
      fetchingRef.current ||
      !mctCurrentPlanYear ||
      !shouldFetch
    ) {
      return;
    }
    const { plan } = fetchCostsParams;
    if (isSearchResultPlan(plan) && plan.remaining_premium_and_drugs) {
      return;
    }
    fetchingRef.current = true;
    fetchCosts(fetchCostsParams).then(fetchCostsReturnValues => {
      updateReturnValues(fetchCostsReturnValues);
    });
  }, [fetchCostsParams, mctCurrentPlanYear, observedElementRef, shouldFetch]);

  return fetchCostsReturnValues;
};

export const fetchDrugInfo = async ({
  plan,
  prescriptions,
}: {
  plan: IdParts;
  prescriptions: PrescriptionDrug[];
}): Promise<DrugInfo[] | undefined> => {
  const ndcs = prescriptions.map(p => p.ndc);

  if (ndcs.length) {
    const planUrl = makePlanUrlPartForApi(plan);
    try {
      const res = await getOtherDrugInfo(ndcs, planUrl);
      return res;
    } catch (e) {
      logError(
        "Failed to get other drug information (OtherDrugInfo)",
        e as ApiError
      );
      return undefined;
    }
  }

  return undefined;
};

/**
 * Categorize different estimated total yearly drug costs for a pharmacy - given
 * a specific plan and choice of drugs - for display on map markers. Amounts classified
 * in each `DrugCostMapDisplayLevel` will be formatted differently, and may also be
 * used to adjust map marker dimensions.
 * - Above $99.99, round drug costs to the nearest dollar, don't display cents.
 * - Equal to or below $99.99, display dollars and cents
 * - except, display $0 without cents
 * - Highest cost to display in a map marker will be $9,999, with all higher drug
 *   costs listed inside the map marker as "10K+"
 * @see `drugCostLevelToMarkerDisplayMap`
 */
export const getDrugCostMapDisplayLevel = (
  cost: number
): DrugCostMapDisplayLevel => {
  const roundedCost = Math.round(cost);
  switch (true) {
    case cost === 0:
      return DrugCostMapDisplayLevel.Zero;
    case cost < 100 && cost > 0:
      return DrugCostMapDisplayLevel.UnderOneHundred;
    case cost >= 100 && roundedCost < 10000:
      return DrugCostMapDisplayLevel.UnderTenThousand;
    default:
      return DrugCostMapDisplayLevel.OverTenThousand;
  }
};

export const drugCostLevelToMarkerDisplayMap: Record<
  DrugCostMapDisplayLevel,
  (cost: number) => string
> = {
  [DrugCostMapDisplayLevel.Zero]: () => "$0",
  [DrugCostMapDisplayLevel.UnderOneHundred]: cost => $$(cost),
  [DrugCostMapDisplayLevel.UnderTenThousand]: cost => $$Whole(Math.round(cost)),
  [DrugCostMapDisplayLevel.OverTenThousand]: () => "$10K+",
};

/**
 * Takes in `DrugCostInfo` for a plan with a specific set of pharmacy `npi`s and
 * returns a mapping of each `npi` to an object that includes
 * - its formatted total yearly estimated cost
 * - another string (optional) formatted for display on map markers
 * - a `DrugCostMapDisplayLevel` that's used to conditionally resize map markers
 */
export const getYearlyDrugCostForPharmacies = ({
  drugCosts,
  npis,
  plan,
  t,
}: {
  drugCosts: DrugCostInfo;
  npis: string[];
  plan: Plan | SearchResultPlan;
  t: ReturnType<typeof useTypedTranslate>;
}): DrugCostDisplayInfoByPharmacy => {
  const suppressDrugPricing = shouldSuppressDrugPricing(plan.redactions);
  const npisToCostsMap = npis.reduce((costsMap, currNpi) => {
    const pharmCosts = drugCosts.costs.find(
      pharmacy => normalizeNpi(pharmacy.npi) === currNpi
    );
    let costOutput = undefined;
    if (pharmCosts) {
      const mapDisplayLevel = getDrugCostMapDisplayLevel(
        pharmCosts.estimated_yearly_total
      );
      costOutput = {
        formatted: suppressDrugPricing
          ? t("na")
          : $$(pharmCosts.estimated_yearly_total),
        mapDisplay: suppressDrugPricing
          ? undefined
          : drugCostLevelToMarkerDisplayMap[mapDisplayLevel](
              pharmCosts.estimated_yearly_total
            ),
        drugCostMapDisplayLevel: suppressDrugPricing
          ? undefined
          : mapDisplayLevel,
      };
    }
    costsMap[currNpi] = costOutput;
    return costsMap;
  }, {} as DrugCostDisplayInfoByPharmacy);
  return npisToCostsMap;
};

export const useUpdateMailOrderNetworkStatus = (drugCostInfo: DrugCostInfo) => {
  const {
    state: { mailOrderNetworkStatus, pharmacyType },
    dispatch,
  } = useAppContext();
  const { costs: pharmacyCosts } = drugCostInfo;
  let mailOrderPharmacyCost: DrugCosts | undefined;
  if (hasMailOrderPharmacy({ pharmacyType })) {
    mailOrderPharmacyCost = pharmacyCosts.find(
      pharmacy => normalizeNpi(pharmacy.npi) === mailOrderNpi
    );
  }
  const mailOrderNetworkStatusNeedsUpdating =
    (!mailOrderPharmacyCost && !!mailOrderNetworkStatus) ||
    (!!mailOrderPharmacyCost && !mailOrderNetworkStatus) ||
    (!!mailOrderPharmacyCost &&
      !!mailOrderNetworkStatus &&
      (mailOrderNetworkStatus.inNetwork !== mailOrderPharmacyCost.in_network ||
        mailOrderNetworkStatus.preferred !== mailOrderPharmacyCost.preferred));

  // Update AppState.MailOrderPharmacyNetworkStatus, if needed
  useEffect(() => {
    if (mailOrderNetworkStatusNeedsUpdating) {
      dispatch({
        type: ActionType.SET_MAIL_ORDER_PHARMACY_NETWORK_STATUS,
        payload: {
          preferred: mailOrderPharmacyCost?.preferred || false,
          inNetwork: mailOrderPharmacyCost?.in_network || false,
        },
      });
    }
  }, [dispatch, mailOrderNetworkStatusNeedsUpdating, mailOrderPharmacyCost]);
};
