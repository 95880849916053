import React, { FunctionComponent, ReactNode, useEffect } from "react";
import { Ga4ParentComponentType } from "../app/contexts/Analytics/types";
import { getIsInternalAnchorLinkHref } from "../helpers/urlHelpers";
import { sendAnalyticsEvent } from "../app/contexts/Analytics";
import { useAppContext } from "../helpers/context-hooks/useAppContext";

/**
 * Data attribute to be added to the `<a>` tag to override the default
 * analytic event
 */
export const CUSTOM_INTERNAL_LINK_CLICKED_EVENT =
  "data-custom-internal-link-clicked-event";

/**
 * Specifies the `parent_component_heading` value in the internal link clicked analytic event
 * @example
 *
 *  <a data-parent-component-heading="my heading" href="https://foo">click me</a>
 */
export const PARENT_COMPONENT_HEADING_ATTRIBUTE =
  "data-parent-component-heading";

/**
 * Specifies the `parent_component_type` value in the internal link clicked analytic event
 * @example
 *
 *  <a data-parent-component-type="alert" href="https://foo">click me</a>
 */
export const PARENT_COMPONENT_TYPE_ATTRIBUTE = "data-parent-component-type";

/**
 * AnalyticsListeners listens for click event and finds the closest
 * `<a>` tag to send INTERNAL_LINK_CLICKED analytic event.
 * The default event would include these properties:
 *  - link_text
 *  - link_url
 *
 * If more properties are needed, add the `data-custom-internal-link-clicked-event` data attribute to the `<a>` tag to override the default analytic event.
 *
 * Example:
 *
 * ```
 * <a data-custom-internal-link-clicked-event="true" href="#/m/plans">Change my search criteria.</a>
 * ```
 */
const AnalyticsListeners: FunctionComponent<{ children?: ReactNode }> = ({
  children,
}) => {
  const { dispatch } = useAppContext();

  useEffect(() => {
    function sendInternalLinkClickEvent(event: Event) {
      const target = event.target as HTMLElement;
      const link = target.closest("a");

      if (link) {
        const isInternalAnchorLink = getIsInternalAnchorLinkHref(link.href);
        const attr = link.attributes.getNamedItem(
          CUSTOM_INTERNAL_LINK_CLICKED_EVENT
        );
        if (
          attr ||
          isInternalAnchorLink ||
          // Design system Tooltip components incorrectly use an anchor tag instead of button
          link.classList.contains("ds-c-tooltip__trigger-link")
        ) {
          // do not send default internal link clicked event
          return;
        }
        sendAnalyticsEvent({
          event_name: "internal_link_clicked",
          text: link.innerText,
          link_type: "link_other",
          link_url: link.getAttribute("href") || "N/A",
          parent_component_heading:
            link.getAttribute(PARENT_COMPONENT_HEADING_ATTRIBUTE) || "N/A",
          parent_component_type: (link.getAttribute(
            PARENT_COMPONENT_TYPE_ATTRIBUTE
          ) || Ga4ParentComponentType.NA) as Ga4ParentComponentType,
        });
      }
    }

    window.addEventListener("click", sendInternalLinkClickEvent);

    return () =>
      window.removeEventListener("click", sendInternalLinkClickEvent);
  }, [dispatch]);

  return <>{children}</>;
};

export default AnalyticsListeners;
