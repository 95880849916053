/**
 * For URLs that start with `medicare.gov`.
 * Create a helper like `linkTo<something>` which would call `getLinkWithLanguage`
 */
export const mGovUrls = {
  avoidPenalties: "medicare.gov/basics/costs/medicare-costs/avoid-penalties",
  avoidPartDPenalties:
    "medicare.gov/basics/costs/medicare-costs/avoid-penalties#part-D-LEP",
  callMedicare: "1-800-633-4227",
  contacts: "medicare.gov/contacts",
  costHelp: "medicare.gov/basics/costs/help",
  coverageWizard: "medicare.gov/medicarecoverageoptions/",
  drugCoveragePartD: "medicare.gov/drug-coverage-part-d",
  endStageRenalDisease: "medicare.gov/basics/end-stage-renal-disease",
  getHelpPayingCosts: "medicare.gov/basics/costs/help",
  getStartedWithMedicare: "medicare.gov/basics/get-started-with-medicare",
  howDoMedicareAdvantagePlansWork:
    "medicare.gov/health-drug-plans/health-plans",
  howOriginalMedicareWorks:
    "medicare.gov/what-medicare-covers/your-medicare-coverage-choices/how-original-medicare-works",
  joiningAPlan:
    "medicare.gov/sign-up-change-plans/joining-a-health-or-drug-plan",
  lowerPrescriptionCosts: "medicare.gov/basics/costs/help/drug-costs",
  medicareAndYou: "medicare.gov/medicare-and-you",
  medicareHome: "medicare.gov",
  medigapBasics: "medicare.gov/health-drug-plans/medigap/basics",
  medigapGuaranteedIssueRights:
    "medicare.gov/supplements-other-insurance/when-can-i-buy-medigap/guaranteed-issue-rights",
  medigap: "medicare.gov/health-drug-plans/medigap",
  medigapInfo:
    "medicare.gov/supplements-other-insurance/whats-medicare-supplement-insurance-medigap",
  medigapCostsOfPolicies: "medicare.gov/health-drug-plans/medigap/basics/costs",
  medigapStateInsuranceDepartments: "medicare.gov/Contacts/#resources/sids",
  medigapWaiverStateInfo:
    "medicare.gov/supplements-other-insurance/how-to-compare-medigap-policies/medigap-in-",
  paceHome: "medicare.gov/pace",
  paceInfo:
    "medicare.gov/health-drug-plans/health-plans/your-coverage-options/other-medicare-health-plans/PACE",
  partDCoPaymentCoInsurance:
    "medicare.gov/drug-coverage-part-d/costs-for-medicare-drug-coverage/copaymentcoinsurance-in-drug-plans",
  partDLateEnrollmentPenalty:
    "medicare.gov/drug-coverage-part-d/costs-for-medicare-drug-coverage/part-d-late-enrollment-penalty",
  prescriptionCostHelp: "medicare.gov/basics/costs/help/drug-costs",
  prescriptionPaymentPlan: "medicare.gov/prescription-payment-plan",
  snp: "medicare.gov/health-drug-plans/health-plans/your-coverage-options/SNP",
  spap: "medicare.gov/pharmaceutical-assistance-program/#state-programs",
  secondaryCallMedicare: "1-877-486-2048",
  specialEnrollmentPeriodInfo:
    "medicare.gov/basics/get-started-with-medicare/get-more-coverage/joining-a-plan",
  talkToSomeone: "medicare.gov/talk-to-someone",
  typesOfHealthPlans:
    "medicare.gov/sign-up-change-plans/different-types-of-medicare-health-plans",
  whatIsMedicareMSA:
    "medicare.gov/health-drug-plans/health-plans/your-coverage-options/MSA",
  whatMedicareCovers: "medicare.gov/what-medicare-covers",
  whenMedicareStarts:
    "medicare.gov/basics/get-started-with-medicare/sign-up/when-does-medicare-coverage-start",
  whenCanISignUpForMedicare:
    "medicare.gov/basics/get-started-with-medicare/sign-up/when-can-i-sign-up-for-medicare",
} as const;
