import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@cmsgov/ds-medicare-gov";
import classNames from "classnames";
import uniqueid from "lodash.uniqueid";
import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  TableHTMLAttributes,
  useEffect,
  useState,
} from "react";
import {
  ColumnInstance,
  HeaderGroup,
  HeaderProps,
  Renderer,
  Row,
  TableInstance,
  useGlobalFilter,
  UseGlobalFiltersInstanceProps,
  useTable,
} from "react-table";
import {
  AugmentedColumn,
  AugmentedHeaderGroup,
  CaptionProp,
  CustomUseTableOptions,
  InitialTableState,
} from "../../@types/react-table";
import {
  getColSpanValue,
  hasEmptyHeader,
  useBelowLargeBreakpoint,
  useFilterTypes,
  useTableCaption,
} from "../../helpers/tableHelpers";

export const tableSubcaptionTestId = "tableSubcaption";

// Suppress CMSDS/MDS table warnings about stacked titles
// Redefine console warn
const _warn = console.warn;

// Allow only warnings that aren't about stacked titles or missing `headers` prop
// in `TableCell`
// @TODO - remove when the CMSDS/MDS are updated to allow suppressing these warnings
// from the `Table` component
// @see https://github.com/CMSgov/design-system/pull/1082
// eslint-disable-next-line @typescript-eslint/no-explicit-any
console.warn = (...args: [any?, ...any[]]) => {
  const stackedTitleWarning =
    "The stackedTitle prop in `TableCell` is required for stackable tables. This prop is displayed for the data cell in the responsive stacked view.";
  const missingHeadersWarning =
    "The headers prop in `TableCell` is required for stackable tables. This prop is needed to associate the headings with data cells in the responsive stacked view.";
  const regEx = new RegExp(`^${stackedTitleWarning}|${missingHeadersWarning}$`);
  if (regEx.test(args[0] as string)) return;
  return _warn.apply(console, args);
};

// Using Partial here to deal with "children" being required on CMSDS Table(??)
// Is there a better way? Tried Omit and re-adding "chilren?: ReactNode | null"
type CMSDSTableProps = Partial<React.ComponentPropsWithoutRef<typeof Table>>;
type TableCellProps = React.ComponentPropsWithoutRef<typeof TableCell>;

// Need to define a FC for TableFoot to avoid errors nesting a native DOM element
// directly within the CMSDS/MDS Table component
const TableFoot: FunctionComponent<
  PropsWithChildren<TableHTMLAttributes<HTMLTableSectionElement>>
> = ({ children, className }) => (
  <tfoot className={className} role="rowgroup">
    {children}
  </tfoot>
);

const ColGroup: FunctionComponent<
  PropsWithChildren<TableHTMLAttributes<HTMLTableElement>>
> = ({ children }) => <colgroup>{children}</colgroup>;

// Values you can nest in `filterConfig` within the `state` prop, passed to
// `RenderedTable`, jnclude:
// - The `label` for the `select` input (DS Dropdown)
// - The `buttonLabel` for the button (DS Button) that applies the selected filter
// - A `showActiveFilter` boolean that conditions the display of applied filter info
//   below the table
// - A `hideFilteredRowHeading` boolean for optionally hiding row headings on filtered
//   tables in stacked view
export interface FilterConfig {
  ariaLabel?: string;
  buttonLabel?: string;
  hideFilteredRowHeading?: boolean;
  label?: string;
  showActiveFilter?: boolean;
  selectedItemInfo?: React.ReactNode;
}

export type RenderedTableStateProps = Record<string, unknown> & {
  filterConfig?: FilterConfig;
};

export interface RenderedTableProps {
  alertMessage?: JSX.Element;
  appliedFilterContent?: (filterValue: string) => JSX.Element;
  autoResetGlobalFilter?: boolean;
  caption?: CaptionProp | null;
  captionSrOnly?: boolean;
  filterValue?: string;
  id?: string;
  isOpen?: boolean;
  liveContent?: boolean;
  // true to display multiple rows of footer content
  multipleFooterRows?: boolean;
  onAboveBreakpoint?: () => void;
  onBelowBreakpoint?: () => void;
  onFilterReset?: () => void;
  onFilterUpdated?: (filterValue: string) => void;
  resetFilterAtBreakpoint?: boolean;
  // true to display the first column cell as a `th` instead of `td` and add `scope="row"`
  rowHeaders: boolean;
  state?: RenderedTableStateProps;
  subcaption?: ReactNode;
  tableColumns: AugmentedColumn[];
  tableData: Record<string, unknown>[];
}

/**
 * Renders a CMSDS table using `react-table` and a set of tableColumns and tableData
 * @see https://react-table.tanstack.com/
 * @see https://cmsgov.github.io/mgov-design-system/components/table/
 * @see https://github.cms.gov/pages/MedicareGov/mgov-design-system/components/table/
 * @param - A combination of options for configuring BOTH `react-table` and
 * the CMSDS table
 * @returns - A CMSDS Table with data populated appropriately
 */
const RenderedTable: FunctionComponent<
  CMSDSTableProps & RenderedTableProps
> = ({
  alertMessage,
  tableColumns,
  tableData,
  rowHeaders = false,
  className,
  borderless = false,
  stackableBreakpoint = "lg",
  caption = null,
  captionSrOnly = false,
  subcaption = null,
  filterValue = "",
  // @see https://react-table.tanstack.com/docs/api/useGlobalFilter#table-options
  autoResetGlobalFilter = true,
  // Flag indicating that the global filter, used below breakpoint, should be
  // reset above the stackable breakpoint
  resetFilterAtBreakpoint = true,
  // The filter reset callback - Handle in the consuming component
  // @TODO - Consider passing in the filter and/or other values that need to be reset
  onFilterReset = () => {
    // void callback by default
    return;
  },
  // A filter updated callback, called with the state.globalFilter value
  onFilterUpdated = () => {
    // void callback by default
    return;
  },
  // A similar callback for doing things when changed to below the stackable breakpoint
  onBelowBreakpoint = () => {
    // void callback by default
    return;
  },
  // A callback for doing things when changed to above the stackable breakpoint
  onAboveBreakpoint = () => {
    // void callback by default
    return;
  },
  // Use to set information about the currently applied table filter below the
  // table. Alternately use the `Cell` option within column configuration for any
  // table column, returning formatted JSX containing information about the applied
  // filter alongside the returned cell value
  // Null-returning callback by default, when used, pass in the filter value string and return
  // JSX
  appliedFilterContent = () => {
    return null;
  },
  // See RenderedTableFilterProps for defined options to pass in regarding the global
  // filter
  state: passedState = {},
  // Any table that has a global filter will automatically add `aria-live` to the
  // rendered table, otherwise, pass `true` here to enable `aria-live` on any table
  // with dynamic content
  liveContent = false,
  multipleFooterRows = false,
  // Suppress errors applying unknown prop `isOpen` to `Table`
  // If ever needed, can be used for conditional logic or classNames in this file
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  isOpen,
  ...props
}) => {
  // Get custom `react-table` filter(s)
  const filterTypes = useFilterTypes();
  /**
   * Setup for `react-table` using the `useTable` hook
   * @param columns - The memoized array of column configuration and content
   * @param data - The memoized array of table configuration and content
   * @param initialState - Table-level initial state, sets a unique id for each table
   */
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    rows,
    allColumns: tableInstanceColumns,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns: tableColumns,
      data: tableData,
      initialState: {
        ...passedState,
        ...{
          id: uniqueid(),
        },
      } as InitialTableState,
      filterTypes,
      globalFilter: "includes",
      autoResetGlobalFilter: autoResetGlobalFilter,
    } as CustomUseTableOptions<Record<string, unknown>>,
    useGlobalFilter
  ) as TableInstance<Record<string, unknown>> &
    UseGlobalFiltersInstanceProps<Record<string, unknown>>;

  const _state = state as InitialTableState & { globalFilter?: string };
  // default optional value in passed state
  const hideFilteredRowHeading =
    passedState?.filterConfig?.hideFilteredRowHeading === true;

  // State updaters for media query matches
  // At the specified breakpoint for smaller viewport widths, the table is rendered
  // borderless and compact.
  // These are props on the CMSDS component, so not something
  // that can be handled in CSS without knowing the internals of the table and its
  // child components.
  // The `borderless` prop is passed in as an option, so used as initial state
  const [borderlessValue, setBorderlessValue] = useState(borderless);
  const [belowBreakpoint, setBelowBreakpoint] = useState(false);

  // Combines table and element ids for cell-to-header (td to th) references
  // Avoid id collisions with multiple tables on the same page
  const getId = (id: string) => `table_${_state?.id}-${id}`;

  /**
   * Check to see if any column headers have been specified
   */
  const hasHeaders = tableColumns.some(
    col =>
      col.Header ||
      (col?.columns &&
        (col.columns as AugmentedColumn[]).some(col => col.Header))
  );
  const hasFooters = tableColumns.some(col => {
    return (
      col.Footer ||
      (col?.columns &&
        (col.columns as AugmentedColumn[]).some(col => col.Footer))
    );
  });
  const hasSubcaption = !!subcaption;

  /**
   * Determine whether any headerGroup has a Filter (global) defined
   * @param headerGroup
   * @returns - Whether any headerGroup has a Filter defined in its config
   */
  const isGlobalFilterHeader = (headerGroup: HeaderGroup) =>
    headerGroup?.headers?.length === 1 &&
    Object.prototype.hasOwnProperty.call(headerGroup?.headers[0], "Filter");

  /**
   * Determine whether the the table config contains an Alert to be displayed in
   * a `thead` row, within a column-spanning cell
   */
  const isAlertHeader = (headerGroup: HeaderGroup) => {
    const headers = headerGroup.headers || [];
    const firstHeader = headers[0] as HeaderGroup<Record<string, unknown>> & {
      attributes?: Record<string, unknown>;
    };
    return headers.length === 1 && (firstHeader?.attributes?.alert as boolean);
  };

  const hasGlobalFilter = headerGroups?.some(isGlobalFilterHeader);
  const hasAlert = headerGroups?.some(isAlertHeader);

  const getTableCaption = useTableCaption();

  // Use a helper to update the table based on our large breakpoint media query
  useBelowLargeBreakpoint(matches => {
    if (matches) {
      setBelowBreakpoint(true);
      setBorderlessValue(true);
      onBelowBreakpoint();
    } else {
      setBelowBreakpoint(false);
      setBorderlessValue(false);
      onAboveBreakpoint();
      if (resetFilterAtBreakpoint) {
        setGlobalFilter("");
        onFilterReset();
      }
    }
  });

  // Update filter if it changes
  useEffect(() => {
    setGlobalFilter(filterValue);
  }, [filterValue]);

  // Call callback prop with the latest global filter value, if needed elsewhere
  // (e.g., to filter other tables or things)
  useEffect(() => {
    onFilterUpdated(_state.globalFilter || "");
  }, [_state.globalFilter]);

  const { className: tableClassName, ...tableProps } = getTableProps();
  const { className: tableBodyClassName, ...tableBodyProps } =
    getTableBodyProps();
  const renderTableHead =
    hasHeaders || hasGlobalFilter || hasAlert || hasSubcaption;
  const baseHeaderClassName = "mct-c-table__header";
  const captionClassNames = classNames({
    "ds-u-visibility--screen-reader": captionSrOnly,
    "mct-c-table-caption--with-subcaption": hasSubcaption,
  });
  return (
    // apply the table props
    <div className="mct-c-table__container">
      <Table
        {...tableProps}
        aria-live={liveContent || hasGlobalFilter ? "polite" : undefined}
        stackable
        stackableBreakpoint={stackableBreakpoint}
        className={classNames("mct-c-table", className, tableClassName, {
          "mct-c-table--is-stacked": belowBreakpoint,
        })}
        borderless={borderlessValue}
        style={{ minWidth: "100%" }}
        compact={belowBreakpoint}
        {...props}
      >
        {getTableCaption(caption, captionClassNames)}
        {alertMessage &&
          getTableCaption(alertMessage, "mct-c-table-caption--alert-message")}
        <ColGroup>
          {tableInstanceColumns.map((column, index) => {
            const colGroupClassName = classNames(
              `mct-c-table__column--${index}`,
              { [`${className}__column--${index}`]: className }
            );
            return <col key={index} className={colGroupClassName}></col>;
          })}
        </ColGroup>

        {renderTableHead && (
          <TableHead
            className={classNames("mct-c-table__head", {
              "mct-c-table__head--with-filter": hasGlobalFilter,
              "mct-c-table__head--with-alert": hasAlert,
              "mct-c-table__head--with-subcaption": hasSubcaption,
            })}
          >
            {hasSubcaption && (
              <TableRow
                className={classNames(
                  "mct-c-table__row",
                  "mct-c-table__row--columnheaders",
                  "mct-c-table__row--with-subcaption"
                )}
                key={tableSubcaptionTestId}
              >
                <TableCell
                  className={`${baseHeaderClassName} ${baseHeaderClassName}--with-subcaption`}
                  colSpan={tableInstanceColumns.length}
                  data-testid={tableSubcaptionTestId}
                  id={tableSubcaptionTestId}
                  scope="colgroup"
                >
                  {subcaption}
                </TableCell>
              </TableRow>
            )}
            {hasHeaders &&
              // Loop over the header rows
              (headerGroups as AugmentedHeaderGroup[]).map(headerGroup => {
                // Apply the header row props
                const {
                  className: headerGroupClassName,
                  key: rowKey,
                  ...tableRowProps
                } = headerGroup.getHeaderGroupProps();
                return (
                  /* eslint-disable-next-line react/jsx-key */
                  <TableRow
                    key={rowKey}
                    {...tableRowProps}
                    className={classNames(
                      "mct-c-table__row",
                      "mct-c-table__row--columnheaders",
                      headerGroupClassName,
                      {
                        "mct-c-table__row--with-filter":
                          isGlobalFilterHeader(headerGroup),
                        "mct-c-table__row--with-alert":
                          isAlertHeader(headerGroup),
                      }
                    )}
                  >
                    {
                      // Loop over the headers in each row
                      headerGroup.headers.map(
                        (column: AugmentedHeaderGroup, colIndex, colArray) => {
                          // Apply the header cell props
                          const {
                            className: tableHeaderCellClassName,
                            key: headerCellKey,
                            ...tableHeaderCellProps
                          } = column.getHeaderProps([
                            {
                              className: column.className,
                              style: column.style,
                            },
                          ]);
                          let headerContents =
                            null === column?.Header ||
                            column?.Header ===
                              "" ? null : typeof column?.Header === "string" ? (
                              <div className="mct-c-table__header-title">
                                {column?.Header}
                              </div>
                            ) : (
                              column?.render("Header")
                            );
                          const hasFilter =
                            Object.prototype.hasOwnProperty.call(
                              column,
                              "Filter"
                            );
                          const isGlobalFilterHeader =
                            column?.columns && hasFilter;
                          const onlyOneHeaderIsDefined =
                            colIndex === 0 && colArray.length === 1;
                          const onlyFirstHeaderHasContent = colArray.reduce(
                            (bool, col, i) => {
                              if (i === 0) {
                                bool = null !== col.Header;
                              } else {
                                bool = null === col.Header;
                              }
                              return bool;
                            },
                            false
                          );
                          const spansAllColumns =
                            onlyOneHeaderIsDefined || onlyFirstHeaderHasContent;
                          if (hasFilter) {
                            headerContents = (
                              <>
                                {headerContents}
                                <div className="mct-c-table__filter">
                                  {column?.render("Filter")}
                                </div>
                              </>
                            );
                          }
                          return (
                            <TableCell
                              key={headerCellKey}
                              {...tableHeaderCellProps}
                              id={getId(column.id)}
                              className={classNames(
                                tableHeaderCellClassName,
                                baseHeaderClassName,
                                "mct-c-table__column-header",
                                {
                                  [`${baseHeaderClassName}--empty`]:
                                    !hasFilter &&
                                    !isGlobalFilterHeader &&
                                    hasEmptyHeader({ column }),
                                },
                                {
                                  [`${baseHeaderClassName}--with-global-filter`]:
                                    hasFilter &&
                                    isGlobalFilterHeader &&
                                    spansAllColumns,
                                },
                                {
                                  [`${baseHeaderClassName}--with-alert`]:
                                    isAlertHeader(headerGroup) &&
                                    spansAllColumns,
                                }
                              )}
                            >
                              {/* Render the header */}
                              {headerContents}
                            </TableCell>
                          );
                        }
                      )
                    }
                  </TableRow>
                );
              })}
          </TableHead>
        )}

        {/* Apply the table body props */}
        <TableBody
          {...tableBodyProps}
          className={classNames(tableBodyClassName, "mtc-c-table__body")}
        >
          {
            // Loop over the table rows
            rows.map((row: Row & { attributes?: Record<string, unknown> }) => {
              // Prepare the row for display
              prepareRow(row);
              // Add custom attributes added to the row in the object "attributes"
              const {
                className: tableRowClassName,
                key: rowKey,
                ...tableRowProps
              } = row.getRowProps(row?.attributes || {});

              return (
                // Apply the row props -- The keys are generated, so eslint warnings silenced
                /* eslint-disable-next-line react/jsx-key */
                <TableRow
                  key={rowKey}
                  {...tableRowProps}
                  className={classNames(tableRowClassName, "mct-c-table__row")}
                >
                  {
                    // Loop over the rows cells
                    row.cells.map((cell, index, _array) => {
                      const column = cell?.column as ColumnInstance & {
                        className?: string;
                      };
                      // Determine whether the current cell should have a colspan attribute
                      const colSpanValue = getColSpanValue({
                        index,
                        cellContentsArray: _array,
                      });
                      // Allow column-spanning cells to expand into the next column
                      // by returning null for valueless cells
                      if (null === cell?.value) {
                        return null;
                      }
                      // Get column header to determine how to build stacked display
                      const header = cell?.column?.Header as Renderer<
                        HeaderProps<Record<string, unknown>>
                      > & { name?: string; props?: typeof React.Children };
                      // Get the cell props from `react-table`, allow className to
                      // be merged with other values
                      const {
                        className: tableBodyCellClassName,
                        key: bodyCellKey,
                        ...tableBodyCellProps
                      } = cell.getCellProps();
                      // If the rowHeaders prop was passed in, and this is the first
                      // column, make the cell a `th` instead of a `td` and add `scope="row"`
                      const makeRowHeader = rowHeaders && index === 0;
                      const hasRowHeader = rowHeaders && index !== 0;
                      // @TODO - Revisit implementation to handle all types of headers
                      const hasStackedColumnTitle = typeof header === "string";
                      // @TODO - Perhaps a better way of determining string vs "complex"
                      // header content (and ultimately differentiating from emptyRenderer)?
                      const hasComplexColumnHeader =
                        header?.props?.children ||
                        header?.name?.includes("Header");
                      const addStackedHideHeaderClass =
                        hideFilteredRowHeading &&
                        _state?.globalFilter &&
                        makeRowHeader;
                      const conditionalProps = {
                        component: makeRowHeader ? "th" : "td",
                        className: classNames(
                          tableBodyCellClassName,
                          makeRowHeader
                            ? "mct-c-table__header mct-c-table__row-header"
                            : "mct-c-table__cell",
                          {
                            "mct-c-table__header--hide-stacked":
                              addStackedHideHeaderClass,
                          },
                          column?.className // Add a column-level class name, if one exists
                        ),
                      } as {
                        component: TableCellProps["component"];
                        scope?: TableCellProps["scope"];
                        stackedTitle?: TableCellProps["stackedTitle"];
                        className?: TableCellProps["className"];
                        id?: TableCellProps["id"];
                        role?: TableCellProps["role"];
                        colSpan?: TableCellProps["colSpan"];
                        headers?: TableCellProps["headers"];
                      };
                      const rowHeaderId = getId(`row${row.id}`);
                      if (makeRowHeader) {
                        conditionalProps.scope = "row";
                        conditionalProps.role = "rowheader";
                        conditionalProps.id = rowHeaderId;
                      }
                      if (colSpanValue > 1) {
                        conditionalProps.colSpan = colSpanValue;
                      }
                      // @TODO - Re-examine both this and the "complex" column header
                      // approach. Leaving them working differently, for the moment,
                      // until we can have a full A11Y review of how we approach
                      // dealing with headers that contain HTML
                      // - Simple text header: rely on CMSDS `Table` approach, use
                      //   ::before pseudo-element `content` to display string from
                      //   `stackedTitle` prop
                      // - Complex header: Render content that includes the column header
                      //   and cell contents, together. The original column header row
                      //   is `aria-hidden` from screen readers
                      if (hasStackedColumnTitle)
                        conditionalProps.stackedTitle = cell?.column
                          ?.Header as string;
                      if (makeRowHeader && typeof cell?.value === "string") {
                        conditionalProps.className = classNames(
                          conditionalProps.className,
                          "mct-c-table__header-title"
                        );
                      }
                      let cellContents = cell.render("Cell");
                      if (hasComplexColumnHeader && belowBreakpoint) {
                        cellContents = (
                          <>
                            {cell.render("Header")}
                            {cellContents}
                          </>
                        );
                      }
                      // @TODO - re-examine how to deal with responsive rendering of
                      // column header contents, to ensure correct A11Y
                      // `headers` here is an HTML attribute for referencing column
                      // and/or row headers by id
                      if (
                        !hasEmptyHeader({ column: cell.column }) ||
                        (!makeRowHeader && hasRowHeader)
                      ) {
                        conditionalProps.headers = "";
                        if (!hasEmptyHeader({ column: cell.column })) {
                          conditionalProps.headers = getId(cell?.column?.id);
                        }
                        if (!makeRowHeader && hasRowHeader) {
                          // headers can reference both a column and row header
                          conditionalProps.headers = `${conditionalProps.headers} ${rowHeaderId}`;
                        }
                      }

                      return (
                        /* eslint-disable-next-line react/jsx-key */
                        <TableCell
                          key={bodyCellKey}
                          {...tableBodyCellProps}
                          {...conditionalProps}
                        >
                          {/* Render the cell contents */}
                          {cellContents}
                        </TableCell>
                      );
                    })
                  }
                </TableRow>
              );
            })
          }
        </TableBody>

        {hasFooters && (
          <TableFoot className="mct-c-table__foot">
            {/* 
            Loop over the footer rows, always using the first unless overridden
            by the prop `multipleFooterRows`. The example table mirrors footer
            structure as the reverse of header structure, including when there
            are multiple header rows. This is not typically desired
            @see https://react-table-v7.tanstack.com/docs/examples/footers
            Note that `footerGroups` is just `headerGroups` in reverse order
          */}
            {footerGroups.map((footerGroup, index) => {
              if (!multipleFooterRows && index > 0) {
                return null;
              }
              const footerGroupHeaders =
                footerGroup.headers as AugmentedHeaderGroup[];
              // Apply the footer row props
              const {
                className: footerGroupClassName,
                key: footerRowKey,
                ...tableFooterRowProps
              } = footerGroup.getFooterGroupProps();
              // Loop over the columns in this footer row
              // First determine whether there are nested columns, and if so
              // iterate over the nested columns instead
              const footerCols =
                footerGroupHeaders[0]?.columns &&
                footerGroupHeaders[0]?.columns?.length > 0
                  ? (footerGroupHeaders[0].columns as AugmentedHeaderGroup[])
                  : footerGroupHeaders;
              const tableConfigColumns =
                tableColumns[0]?.columns && tableColumns[0]?.columns?.length > 0
                  ? tableColumns[0].columns
                  : tableColumns;
              const footerRowContents = footerCols.map(
                (column, index, _array) => {
                  const Footer = column?.Footer as ReactNode;
                  // Apply the footer cell props
                  const {
                    className: tableFooterCellClassName,
                    key: footerCellKey,
                    ...tableFooterCellProps
                  } = column.getFooterProps();
                  // Derive a map of footer values, setting empty values to null
                  const footerArray = _array.map((column, colIndex) =>
                    !tableConfigColumns[colIndex]?.Footer ? null : Footer
                  );
                  // Get colspan values
                  const colSpanValue = getColSpanValue({
                    index,
                    cellContentsArray: footerArray,
                  });

                  // Figure out how to render the footer contents
                  // For the 1st cell, if it doesn't span cols and the table has
                  // rowHeaders, wrap for formatting, otherwise just render
                  const footerContents =
                    rowHeaders &&
                    colSpanValue === 1 &&
                    typeof Footer === "string" &&
                    Footer.length > 0 &&
                    index === 0 ? (
                      <div className="mct-c-table__header-title">{Footer}</div>
                    ) : null !== Footer && Footer !== "" ? (
                      column?.render("Footer")
                    ) : null;
                  // Return null if this footer is empty
                  if (!tableConfigColumns[index]?.Footer || !footerContents) {
                    return null;
                  }
                  // Else return the footer cell
                  return (
                    /* eslint-disable-next-line react/jsx-key */
                    <TableCell
                      {...tableFooterCellProps}
                      key={footerCellKey}
                      id={`footer_${getId(column.id)}`}
                      className={classNames(
                        tableFooterCellClassName,
                        "mct-c-table__footer",
                        "mct-c-table__cell"
                      )}
                      colSpan={colSpanValue}
                    >
                      {/* Render the footer */}
                      {footerContents}
                    </TableCell>
                  );
                }
              );
              const hasFooterRowContents = footerRowContents.some(
                cell => null !== cell
              );
              return hasFooterRowContents ? (
                <TableRow
                  key={footerRowKey}
                  {...tableFooterRowProps}
                  className={classNames(
                    "mct-c-table__row",
                    footerGroupClassName
                  )}
                >
                  {footerRowContents}
                </TableRow>
              ) : null;
            })}
          </TableFoot>
        )}
      </Table>
      {passedState?.filterConfig?.showActiveFilter &&
        _state?.globalFilter &&
        appliedFilterContent(_state.globalFilter)}
    </div>
  );
};

export default RenderedTable;
