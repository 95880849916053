import { getDrugCosts } from "../../api";
import { useQuery } from "@tanstack/react-query";
import { QueryKey } from "./types";
import { useAppContext } from "../context-hooks/useAppContext";
import {
  getDefaultDrugCostInfo,
  getPharmacyNpis,
  getPrescriptionInfoForDrugCosts,
  useUpdateMailOrderNetworkStatus,
} from "../drugCostsHelpers";
import { useMemo } from "react";
import { useLIS } from "../context-hooks/useLIS";
import { getFullPlanId } from "../objectUtilities";
import { LowIncomeSubsidyStatus, Plan, SearchResultPlan } from "../../@types";
import { useTypedTranslate } from "../intlHooks";
import { useBackendVersions } from "./useBackendVersions";

/**
 * fetches drug costs for a specific plan
 * @param plan {Plan}
 * @param options - booleans `fullYearPricing` and `retailOnly`
 *
 * @example
 * useDrugCosts(Plan, {
 *  fullYearPricing: true,
 * })
 */
export const useDrugCosts = (
  plan: Plan | SearchResultPlan,
  options?: {
    /** `fullYearPricing` is used for Year Over Year comparison, for example */
    fullYearPricing?: boolean;
    /**
     * @TODO: Eventually, this boolean should probably be set inside `useDrugCosts`
     * based on a check of `hasMailOrderPharmacy({pharmacyType})`, and not included
     * as an option for calling code
     * Check out https://jira.cms.gov/browse/MCT-8227 for ticket to address this.
     * Check out https://github.cms.gov/CMS-MCT/coverage-tools-frontend/pull/2628
     * for additional context for when this parameter was originally added.
     */
    retailOnly?: boolean;
    disabled?: boolean;
  }
) => {
  // * Options
  const {
    fullYearPricing = false,
    retailOnly = false,
    disabled = false,
  } = options || {};

  // * Translation
  const t = useTypedTranslate();

  //* Queries
  const { drugDbTimestamp, staleTime } = useBackendVersions();

  // * Context
  const {
    state: { pharmacies, prescriptions: statePrescriptions },
  } = useAppContext();
  const { getLISForYear } = useLIS();

  // * Memos
  const npis = useMemo(() => getPharmacyNpis(pharmacies), [pharmacies]);
  const prescriptions = useMemo(
    () => getPrescriptionInfoForDrugCosts(statePrescriptions),
    [statePrescriptions]
  );
  const planIds = useMemo(() => [getFullPlanId(plan)], [plan]);

  // * Constants
  const lis =
    getLISForYear(+plan.contract_year) || LowIncomeSubsidyStatus.LIS_NO_HELP;
  const enabled = !!planIds.length && !disabled;

  const placeholderDrugCostInfo = useMemo(
    () => getDefaultDrugCostInfo({ plan, t }),
    [plan, t]
  );

  // * Queries
  const { isPending, isError, data } = useQuery({
    queryKey: [
      QueryKey.DrugCosts,
      drugDbTimestamp,
      {
        npis,
        prescriptions,
        planIds,
        lis,
        fullYearPricing,
        retailOnly,
      },
    ],
    queryFn: () => {
      return getDrugCosts({
        npis,
        prescriptions,
        planIds,
        lis,
        fullYearPricing,
        retailOnly,
      });
    },
    enabled,
    staleTime,
    placeholderData: {
      full_year: true,
      request_id: "",
      plans: [placeholderDrugCostInfo],
    },
  });

  const drugCostInfo = useMemo(() => {
    return data?.plans[0] || placeholderDrugCostInfo;
  }, [data?.plans, placeholderDrugCostInfo]);

  useUpdateMailOrderNetworkStatus(drugCostInfo);

  const cumulativeMeetsAnnualCap = drugCostInfo.cumulative_meets_annual_cap;

  return {
    isPending,
    isError,
    drugCostInfo,
    cumulativeMeetsAnnualCap,
  };
};
