import { useCallback } from "react";
import { sendAnalyticsEvent } from "../../app/contexts/Analytics";
import { useLoggedIn } from "../loginHelpers";
import { useYearPart } from "../yearFlagHelpers";
import { assert } from "../devHelpers";
import { BasePlan } from "../../@types";
import { useEnrollPlan } from "../query-hooks/useEnrollPlan";

/** helper hook for sending enrollment related analytics */
export function useEnrollmentAnalytics() {
  // * Context
  const logged_in = useLoggedIn();

  // * Flags
  const { isOutsideOpenEnrollment } = useYearPart();
  /** for analytics, this should be true for any time outside of OOE */
  const open_enrollment = !isOutsideOpenEnrollment;

  // * Queries
  const { plan: selectedPlan } = useEnrollPlan();

  // * Constants
  const mct_insurance_provider = selectedPlan?.name || "";
  const mct_insurance_type = selectedPlan?.plan_type;

  const sendEnrollmentCompletedEvent = useCallback(() => {
    assert(
      mct_insurance_type,
      "We can't complete enrollment without a selectedPlan"
    );
    sendAnalyticsEvent({
      event_name: "enrollment_completed",
      logged_in,
      open_enrollment,
      mct_insurance_provider,
      mct_insurance_type,
    });
  }, [logged_in, mct_insurance_provider, mct_insurance_type, open_enrollment]);

  const sendEnrollmentStartedEvent = useCallback(
    (plan: BasePlan) => {
      sendAnalyticsEvent({
        event_name: "enrollment_started",
        logged_in,
        open_enrollment,
        mct_insurance_provider: plan.name,
        mct_insurance_type: plan.plan_type,
      });
    },
    [logged_in, open_enrollment]
  );

  return {
    sendEnrollmentCompletedEvent,
    sendEnrollmentStartedEvent,
  };
}
