import { getPlan } from "../../api";
import { queryOptions, useQuery, useQueryClient } from "@tanstack/react-query";
import { QueryKey } from "./types";
import { parsePlanLongIdIntoUrlPartForApi } from "../objectUtilities";
import { isValidApiLongPlanId } from "../planHelpers";
import { useLIS } from "../context-hooks/useLIS";
import { Plan, PlanLongId } from "../../@types";
import { useMemo } from "react";
import { useCurrentPlanYear } from "../yearFlagHelpers";
import { useRouteParams } from "../routing-hooks/useRouteParams";
import { useSearchParams } from "../routing-hooks/useSearchParams";
import { useBackendVersions } from "./useBackendVersions";

export interface UsePlanPayload {
  /** a null value indicates a successful endpoint call that fails the typeguard */
  plan?: Plan | null;
  isLoading: boolean;
  error?: Error;
  prefetchPlan: () => void;
}

type UsePlanType = (overrides?: UsePlanOverrides) => UsePlanPayload;

type UsePlanOverrides = {
  /** a specific planId, otherwise, we look at the route */
  planId?: PlanLongId;
  /** a specific year to get LIS for (current or next plan year) */
  lisYear?: number;
  /** prevents the query from being called, useful for if you just want the pre-fetch function */
  disabled?: boolean;
};

/**
 * query hook which fetches a plan
 * @example const plan = usePlan(); // will grab the plan based of the planId in the route
 * @example const plan = usePlan({planId}); // will grab a specific plan
 */
export const usePlan: UsePlanType = (overrides?: UsePlanOverrides) => {
  // * Context
  const { getLISForYear } = useLIS();

  // * Flags
  const mctCurrentPlanYear = useCurrentPlanYear();

  // * Route
  const { planId: routePlanId } = useRouteParams();
  const { selectedYear } = useSearchParams();

  // * Queries
  const { planDbTimestamp, staleTime } = useBackendVersions();

  // * Constants
  const planLongId = overrides?.planId || routePlanId;
  const apiPlanId = planLongId
    ? parsePlanLongIdIntoUrlPartForApi(planLongId)
    : "";

  const planIdIsValid = isValidApiLongPlanId(apiPlanId);
  const enabled = planIdIsValid && !overrides?.disabled;

  /** will use route `year` as a fallback and `mctCurrentPlanYear` if it does not exist */
  const lisToSend = getLISForYear(
    overrides?.lisYear || selectedYear || mctCurrentPlanYear
  );

  // * Queries
  const queryClient = useQueryClient();

  const planOptions = queryOptions({
    queryKey: [QueryKey.Plan, planDbTimestamp, apiPlanId, lisToSend],
    queryFn: () => {
      return getPlan(apiPlanId, lisToSend ? { lis: lisToSend } : {});
    },
  });

  const {
    data,
    error: queryError,
    isLoading,
  } = useQuery({
    ...planOptions,
    enabled,
    staleTime,
  });

  const error = useMemo(() => {
    // error comes from calling the endpoint and not getting a plan back
    if (queryError) return queryError as Error;
    if (!planIdIsValid) {
      // error comes from invalid plan id, where we do not call the endpoint in the first place
      return new Error(`Invalid plan ID (${apiPlanId})`);
    }
    return;
  }, [apiPlanId, planIdIsValid, queryError]);

  const prefetchPlan = () => {
    queryClient.prefetchQuery(planOptions);
  };

  return {
    plan: data,
    isLoading,
    error,
    prefetchPlan,
  };
};
